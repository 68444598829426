export interface IAlertOption {
  alertType: AlertType;
  message: string;
  timer?: number;
  messageParams?: any;
  code?: string;
  data?: string[];
}

export enum AlertType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info'
}
