import { Injectable } from '@angular/core';
import { BaseMockApi } from 'app/mock-api/base.api';
import { project as projectData } from 'app/mock-api/dashboards/project/project.data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ProjectMockApi extends BaseMockApi {
  private _project: any = projectData;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Sales - GET
    // -----------------------------------------------------------------------------------------------------
    this.fuseMockApiService.onGet('api/dashboards/project').reply(() => [200, cloneDeep(this._project)]);
  }
}
