import { inject, Injectable } from '@angular/core';

import { LangDefinition, TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { LanguageActions } from './languages.actions';
import { fromLanguage } from './languages.reducer';

@Injectable()
export class LanguagesEffects implements OnInitEffects {
  readonly actions$ = inject(Actions);
  _translocoService = inject(TranslocoService);
  _store = inject(Store);

  changeLang$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LanguageActions.changeLanguage),
        map(({ selectedId }) => {
          this._translocoService.setActiveLang(selectedId);
        })
      );
    },
    { dispatch: false }
  );

  changeLanguageSuccess$ = createEffect(() =>
    this._translocoService.langChanges$.pipe(
      concatLatestFrom(() => this._store.select(fromLanguage.selectSelectedId)),
      filter(([activeLang, selectedLang]) => activeLang !== selectedLang),
      map(([activeLang]) => LanguageActions.changeLanguageSuccess({ selectedId: activeLang }))
    )
  );

  ngrxOnInitEffects(): Action {
    return LanguageActions.initData({
      languages: [...(this._translocoService.getAvailableLangs() as LangDefinition[])]
    });
  }
}
