import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HotToastService } from '@ngxpert/hot-toast';
import { AlertType, IAlertOption } from 'app/shared/models/alert-option';
import { map } from 'rxjs';
import { AlertActions } from './alert.actions';

@Injectable()
export class AlertEffects {
  actions$ = inject(Actions);
  translate = inject(TranslocoService);
  store = inject(Store);
  toastService = inject(HotToastService);

  showAlterAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertActions.showAlert),
      map(({ code, message, alertType, messageParams, data }: Partial<IAlertOption>) => {
        this.showNotificacion({
          message: code === 'ERR01' || !code ? message : `commons.label.error.${code}`,
          alertType: alertType,
          data,
          messageParams
        });
        return AlertActions.resetAlertAction();
      })
    )
  );

  showNotificacion({ message, messageParams = {}, alertType = AlertType.SUCCESS, timer = 3000, data }: Partial<IAlertOption>) {
    let html = this.translate.translate(message || '', messageParams);
    if (data) {
      html = html + data.map(attribute => `<div class="d-flex justify-content-center mt-2">${attribute} </div>`).join('');
    }
    switch (alertType) {
      case AlertType.SUCCESS: {
        this.toastService.success(html, { duration: timer });
        break;
      }
      case AlertType.WARNING: {
        this.toastService.warning(html, { duration: timer });
        break;
      }
      case AlertType.INFO: {
        this.toastService.info(html, { duration: timer });
        break;
      }
      case AlertType.ERROR: {
        this.toastService.error(html, { duration: timer });
        break;
      }
    }
  }
}
