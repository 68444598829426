import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { UserIdleService } from 'angular-user-idle';
import { AlertActions } from 'app/core/alert/+state/alert.actions';
import { IdleManagementService } from 'app/core/idle-managment/idle-management.service';
import { fromRouter } from 'app/core/router-state/data-access';
import { UserActions } from 'app/core/user/+state/user.actions';
import { fromUser } from 'app/core/user/+state/user.reducer';
import { AUTH_STORAGE_KEY } from 'app/shared/constants/app-const';
import { AlertType } from 'app/shared/models/alert-option';
import { catchError, filter, fromEvent, map, merge, mergeMap, of, switchMap } from 'rxjs';
import { AuthService } from '../../auth.service';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects implements OnInitEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  private router = inject(Router);
  private jwtHelper = inject(JwtHelperService);
  private idleManagementService = inject(IdleManagementService);
  private userIdle = inject(UserIdleService);

  ngrxOnInitEffects(): Action {
    return AuthActions.initialAuth();
  }

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initialAuth),
      filter(() => (localStorage.getItem(AUTH_STORAGE_KEY) ? true : false)),
      map(() =>
        AuthActions.signInWithTokenSuccess({
          accessToken: localStorage.getItem(AUTH_STORAGE_KEY),
          decodedToken: this.jwtHelper.decodeToken(localStorage.getItem(AUTH_STORAGE_KEY))
        })
      )
    )
  );

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signIn),
      switchMap(({ credentials }) => {
        return this.authService.authenticate(credentials).pipe(
          map(({ privateKey: accessToken, status, message }) => {
            if (status === 'success') {
              if (credentials.rememberMe) {
                localStorage.setItem(AUTH_STORAGE_KEY, accessToken);
              }
              return AuthActions.signInSuccess({
                accessToken,
                decodedToken: this.jwtHelper.decodeToken(accessToken),
                rememberMe: credentials.rememberMe
              });
            } else {
              return AuthActions.signInError({
                errorCode: message,
                errorMessage: message
              });
            }
          }),
          catchError(err => {
            return of(
              AuthActions.signInError({
                errorCode: err?.error?.status,
                errorMessage: err?.error?.message
              })
            );
          })
        );
      })
    )
  );

  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInSuccess),
      concatLatestFrom(() => [this.store.select(fromRouter.selectQueryParam('redirectURL'))]),
      mergeMap(([, redirectURL]) => {
        this.router.navigate([redirectURL || '/signed-in-redirect']);
        return [UserActions.initData(), AuthActions.startWatching()];
      })
    )
  );

  signInWithTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInWithTokenSuccess),
      mergeMap(() => {
        return [UserActions.initData(), AuthActions.startWatching()];
      })
    )
  );

  startWatching$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.startWatching),
        map(() => {
          this.idleManagementService.generateTabId();
          this.idleManagementService.setTabStatusList();
          this.idleManagementService.startWatching();
        })
      ),
    { dispatch: false }
  );

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signOut),
        map(() => {
          localStorage.removeItem(AUTH_STORAGE_KEY);
        })
      ),
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      switchMap(({ email, user }) => {
        return this.authService.forgotPassword({ email, usuario: user ?? '' }).pipe(
          mergeMap(() => {
            return [
              AlertActions.showAlert({
                alertType: AlertType.SUCCESS,
                message: 'commons.messages.success-forgot-password'
              }),
              AuthActions.forgotPasswordSuccess()
            ];
          })
        );
      })
    );
  });

  changePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.changePassword),
      concatLatestFrom(() => this.store.select(fromUser.selectUserId)),
      switchMap(([{ newPassword, currentPassword }, userId]) => {
        return this.authService.changePassword(userId, { newPassword, oldPassword: currentPassword }).pipe(
          mergeMap(() => {
            return [
              AlertActions.showAlert({
                alertType: AlertType.SUCCESS,
                message: 'commons.messages.success-change-password'
              }),
              AuthActions.changePasswordSuccess()
            ];
          })
        );
      })
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      concatLatestFrom(() => this.store.select(fromRouter.selectRouteParams)),
      switchMap(([{ password }, params]) => {
        return this.authService.resetPassword(password, params['token']).pipe(
          map(() => {
            return AuthActions.resetPasswordSuccess();
          })
        );
      })
    );
  });

  stopWatching$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.stopWatching, AuthActions.signOut),
        map(() => {
          this.idleManagementService.stopWatching();
        })
      ),
    { dispatch: false }
  );

  lockSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.lockSession),
        concatLatestFrom(() => [this.store.select(fromRouter.selectUrl)]),
        map(([, url]) => {
          localStorage.removeItem(AUTH_STORAGE_KEY);
          this.router.navigate(['/unlock-session'], { queryParams: { redirectURL: url } });
        })
      ),
    { dispatch: false }
  );

  beforeunload$ = createEffect(
    () =>
      fromEvent(window, 'beforeunload').pipe(
        map(() => {
          this.idleManagementService.removeFromTabStatusList();
        })
      ),
    { dispatch: false }
  );

  storage$ = createEffect(
    () =>
      fromEvent(window, 'storage').pipe(
        map((data: StorageEvent) => {
          this.idleManagementService.manageTabStatus(data);
        })
      ),
    { dispatch: false }
  );

  focusAndBlur$ = createEffect(
    () =>
      merge(fromEvent(window, 'focus'), fromEvent(window, 'blur')).pipe(
        map(() => {
          this.idleManagementService.setTabStatusList();
        })
      ),
    { dispatch: false }
  );

  onTimerStart$ = createEffect(() => this.userIdle.onTimerStart().pipe(map(() => {})), { dispatch: false });

  onTimeout$ = createEffect(() =>
    this.userIdle.onTimeout().pipe(
      map(() => {
        this.idleManagementService.setIdleStatus('idle');
        this.idleManagementService.stopWatching();
        return AuthActions.lockSession();
      })
    )
  );
}
