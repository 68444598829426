import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';

export abstract class BaseService {
  readonly apiPublicUrl = environment.apiPublicUrl;
  readonly apiPrivateUrl = environment.apiPrivateUrl;

  _httpClient = inject(HttpClient);
}
