import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, isDevMode } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { provideFuse } from '@fuse';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { provideTranslocoPersistTranslations } from '@jsverse/transloco-persist-translations';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { Store, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { provideUserIdleConfig } from 'angular-user-idle';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import {
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  ReCaptchaV3Service,
  RecaptchaLoaderService,
  RecaptchaSettings,
  RecaptchaV3Module
} from 'ng-recaptcha';
import { AlertEffects } from './core/alert/+state/alert.effects';
import { alertsFeature } from './core/alert/+state/alert.reducer';
import { fromAuth } from './core/auth/data-access/+state/auth.reducer';
import { httpInterceptorProviders } from './core/auth/httpintercetors';
import { clearStateMetaReducer } from './core/clearstate.meta-reducer';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { LanguagesEffects } from './layout/common/languages/+state/languages.effects';
import { languageFeature } from './layout/common/languages/+state/languages.reducer';
import { SettingsEffects } from './layout/common/settings/+state/settings.effects';
import { settingsFeature } from './layout/common/settings/+state/settings.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideHotToastConfig(),
    provideRouter(appRoutes, withPreloading(PreloadAllModules), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
    provideStore(
      {
        router: routerReducer,
        [languageFeature.name]: languageFeature.reducer,
        [alertsFeature.name]: alertsFeature.reducer,
        [settingsFeature.name]: settingsFeature.reducer
      },
      {
        metaReducers: [clearStateMetaReducer],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: false,
          strictStateSerializability: true,
          strictActionSerializability: false,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    provideRouterStore(),
    provideEffects([LanguagesEffects, AlertEffects, SettingsEffects]),
    provideStoreDevtools({
      maxAge: 50, // Retains last 50 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    httpInterceptorProviders,
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: () => {
            const store = inject(Store);
            return store.selectSignal(fromAuth.selectAccessToken)();
          },
          allowedDomains: environment.JWTAllowedDomains
        }
      })
    ),
    RecaptchaV3Module,
    ReCaptchaV3Service,
    RecaptchaLoaderService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptcha.siteKey },
    // Material Date Adapter
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'dd/MM/yyyy'
        },
        display: {
          dateInput: 'dd/MM/yyyy',
          monthYearLabel: 'dd/MM/yyyy',
          dateA11yLabel: 'dd/MM/yyyy',
          monthYearA11yLabel: 'dd/MM/yyyy'
        }
      }
    },
    // Transloco Config
    provideTransloco({
      config: {
        availableLangs: [
          { id: 'de-DE', label: 'Deutsch' },
          { id: 'en-GB', label: 'English' },
          { id: 'es-ES', label: 'Español' },
          { id: 'fr-FR', label: 'Français' },
          { id: 'nl-BE', label: 'Nederlands' },
          { id: 'it-IT', label: 'Italiano' },
          { id: 'pt-PT', label: 'Português' }
        ],
        defaultLang: 'en-GB',
        fallbackLang: 'en-GB',
        reRenderOnLangChange: true,
        prodMode: !isDevMode()
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoPersistTranslations({
      loader: TranslocoHttpLoader, // ====> Auto generated via ng add
      storage: { useValue: localStorage }
    }),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage
      }
    }),
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.reCaptcha.siteKey } as RecaptchaSettings
    },
    // Fuse
    provideIcons(),
    provideFuse({
      mockApi: {
        delay: 0,
        services: mockApiServices
      },
      fuse: {
        layout: 'classy',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px'
        },
        theme: 'theme-brand',
        themes: [
          {
            id: 'theme-default',
            name: 'Default'
          },
          {
            id: 'theme-brand',
            name: 'Brand'
          },
          {
            id: 'theme-teal',
            name: 'Teal'
          },
          {
            id: 'theme-rose',
            name: 'Rose'
          },
          {
            id: 'theme-purple',
            name: 'Purple'
          },
          {
            id: 'theme-amber',
            name: 'Amber'
          }
        ]
      }
    }),
    provideUserIdleConfig({ idle: 600, timeout: 1, ping: 120 })
  ]
};
