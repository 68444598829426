<fuse-loading-bar />

<fuse-vertical-navigation
  class="bg-card dark:bg-gray-900 print:hidden"
  [appearance]="'thin'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.compact"
  [opened]="!isScreenSmall"
>
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex h-20 items-center justify-center">
      <img class="w-14" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<div class="flex w-full min-w-0 flex-auto flex-col">
  <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
  >
    <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <search [appearance]="'bar'"></search>
      <user></user>
    </div>
  </div>

  <div class="flex flex-auto flex-col">
    <router-outlet></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
  >
    <span class="text-secondary font-medium">Perrelet &copy; {{ currentYear }}</span>
  </div>
</div>
