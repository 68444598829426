import { Injectable } from '@angular/core';
import { BaseMockApi } from 'app/mock-api/base.api';
import { responseNavigation } from 'app/mock-api/common/navigation/navigation.data';
import { user } from '../user/user.data';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi extends BaseMockApi {
  registerHandlers(): void {
    const idUsuario = user.idUsuario;
    this.fuseMockApiService.onGet(`${this.apiPublicUrl}/admin/usuarios/main_menu/${idUsuario}`, 1000).reply(() => {
      return [200, responseNavigation];
    });
  }
}
