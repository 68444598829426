import { inject, Injectable } from '@angular/core';
import { FuseConfig, FuseConfigService } from '@fuse/services/config';
import { FUSE_CONFIG } from '@fuse/services/config/config.constants';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { fromRouter } from 'app/core/router-state/data-access';
import { CONFIG_STORAGE_KEY } from 'app/shared/constants/app-const';
import { filter, map } from 'rxjs';
import { SettingsActions } from './settings.actions';
import { fromSettings } from './settings.reducer';

@Injectable()
export class SettingsEffects implements OnInitEffects {
  readonly actions$ = inject(Actions);
  _fuseConfigService = inject(FuseConfigService);
  _fuse_config: FuseConfig = inject(FUSE_CONFIG);
  _store = inject(Store);

  initData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsActions.initData),
      map(() => {
        let config = this._fuse_config;
        if (this.getFuseConfig()) {
          config = { ...config, ...JSON.parse(this.getFuseConfig()) };
        }
        this.setFuseConfig(config);
        return SettingsActions.setData({ config });
      })
    );
  });

  changeLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SettingsActions.changeLayout),
        concatLatestFrom(() => [this._store.select(fromSettings.selectSettingsState)]),
        map(([{ layout }, { scheme, theme }]) => {
          this.setFuseConfig({ layout, theme, scheme });
          this._fuseConfigService.config = { layout };
        })
      );
    },
    { dispatch: false }
  );

  changeScheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SettingsActions.changeScheme),
        concatLatestFrom(() => [this._store.select(fromSettings.selectSettingsState)]),
        map(([{ scheme }, { layout, theme }]) => {
          this.setFuseConfig({ layout, theme, scheme });
          this._fuseConfigService.config = { scheme };
        })
      );
    },
    { dispatch: false }
  );

  changeTheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SettingsActions.changeTheme),
        concatLatestFrom(() => [this._store.select(fromSettings.selectSettingsState)]),
        map(([{ theme }, { layout, scheme }]) => {
          this.setFuseConfig({ layout, theme, scheme });
          this._fuseConfigService.config = { theme };
        })
      );
    },
    { dispatch: false }
  );

  layoutQueryParam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [this._store.select(fromRouter.selectQueryParam('layout'))]),
      filter(([, layout]) => !!layout),
      map(([, layout]) => {
        return SettingsActions.changeLayout({ layout });
      })
    )
  );

  setFuseConfig(config: { layout: string; scheme: string; theme: string }) {
    localStorage.setItem(CONFIG_STORAGE_KEY, JSON.stringify(config));
  }

  getFuseConfig(): string {
    return localStorage.getItem(CONFIG_STORAGE_KEY) ?? '';
  }

  ngrxOnInitEffects(): Action {
    return SettingsActions.initData();
  }
}
