<fuse-loading-bar />

<fuse-vertical-navigation
  class="dark bg-primary print:hidden"
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.default"
  [opened]="!isScreenSmall"
>
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex h-20 items-center justify-center">
      <img class="w-14" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<div class="flex w-full min-w-0 flex-auto flex-col">
  <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
  >
    <div class="flex items-center space-x-2 pr-2">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'" />
      </button>
      <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
        <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'" />
      </button>
    </div>
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
      <languages />
      <fuse-fullscreen class="hidden md:block" />
      <search [appearance]="'bar'" />
      <user />
    </div>
  </div>

  <div class="flex flex-auto flex-col">
    <router-outlet />
  </div>

  <div
    class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
  >
    <span class="text-secondary font-medium">Perrelet &copy; {{ currentYear }}</span>
  </div>
</div>
