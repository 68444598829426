import { Route } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthEffects } from './core/auth/data-access/+state/auth.effects';
import { authFeature } from './core/auth/data-access/+state/auth.reducer';
import { AuthTwoFactorGuard } from './core/auth/guards/auth-two-factor.guard';
import { AutologinGuard } from './core/auth/guards/autologin.guard';
import { NavigationEffects } from './core/navigation/+state/navigation.effects';
import { navigationFeature } from './core/navigation/+state/navigation.reducer';
import { RecaptchaEffects } from './core/recaptcha/+state/recaptcha.effects';
import { recaptchaFeature } from './core/recaptcha/+state/recaptcha.reducer';
import { translateTitle } from './core/transloco/transloco-title.util';
import { UserEffects } from './core/user/+state/user.effect';
import { userFeature } from './core/user/+state/user.reducer';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  {
    path: '',
    providers: [
      provideState(userFeature),
      provideState(authFeature),
      provideState(recaptchaFeature),
      provideEffects([UserEffects, AuthEffects, RecaptchaEffects])
    ],
    children: [
      // Redirect empty path to '/dashboard'
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

      // Redirect signed-in user to the '/dashboard'
      //
      // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
      // path. Below is another redirection for that path to redirect the user to the desired
      // location. This is a small convenience to keep all main routes together here on this file.
      { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

      // Auth routes for guests
      {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
          layout: 'empty'
        },
        children: [
          {
            path: 'confirmation-required',
            loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')
          },
          {
            path: 'forgot-password',
            title: () => translateTitle('commons.label.forgot-password'),
            loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')
          },
          {
            path: 'reset-password',
            loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')
          },
          {
            path: 'sign-in',
            title: () => translateTitle('commons.label.sign-in'),
            loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')
          },
          {
            path: 'autologin/:token',
            canActivate: [AutologinGuard],
            title: () => translateTitle('commons.label.sign-in'),
            loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')
          }
        ]
      },

      // Auth routes for authenticated users
      {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
          layout: 'empty'
        },
        children: [
          {
            path: 'sign-out',
            title: () => translateTitle('commons.label.sign-out'),
            loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')
          },
          //TODO: Traducir Pagina
          {
            path: 'unlock-session',
            title: () => translateTitle('commons.label.forgot-password'),
            loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')
          }
        ]
      },

      // Auth routes for authenticated users with twofactor
      {
        path: '',
        canActivate: [AuthTwoFactorGuard],
        canActivateChild: [AuthTwoFactorGuard],
        component: LayoutComponent,
        data: {
          layout: 'empty'
        },
        children: [
          {
            path: 'two-factor-auth-phone-number',
            title: () => translateTitle('commons.label.two-factor-auth-phone-number'),
            loadChildren: () => import('app/modules/auth/two-factor-auth-new-phone/two-factor-auth-new-phone.routes')
          },
          {
            path: 'two-factor-auth-send-code',
            title: () => translateTitle('commons.label.two-factor-auth-send-code'),
            loadChildren: () => import('app/modules/auth/two-factor-auth-send-code/two-factor-auth-send-code.routes')
          }
        ]
      },

      // Landing routes
      {
        path: '',
        component: LayoutComponent,
        data: {
          layout: 'empty'
        }
        //children: [{ path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') }]
      },

      // Admin routes
      {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        providers: [provideState(navigationFeature), provideEffects([NavigationEffects])],
        component: LayoutComponent,
        children: [
          {
            path: 'dashboard',
            title: () => translateTitle('commons.label.dashboard'),
            loadChildren: () => import('app/modules/dashboard/dashboard.routes')
          },
          {
            path: 'profile',
            title: () => translateTitle('commons.label.profile'),
            loadChildren: () => import('app/modules/profile/profile.routes')
          }
        ]
      }
    ]
  }
];
