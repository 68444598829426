import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { fromUser } from 'app/core/user/+state/user.reducer';

@Component({
  selector: 'user',
  standalone: true,
  templateUrl: './user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, NgClass, MatDividerModule, RouterModule, TranslocoModule]
})
export class UserComponent {
  @Input() showAvatar: boolean = true;

  #store = inject(Store);
  #router = inject(Router);

  user$ = this.#store.selectSignal(fromUser.selectUser);

  signOut(): void {
    this.#router.navigate(['/sign-out']);
  }
}
