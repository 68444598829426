import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';

@Component({
  selector: 'empty-layout',
  templateUrl: './empty.component.html',
  standalone: true,
  imports: [FuseLoadingBarComponent, RouterOutlet]
})
export class EmptyLayoutComponent {}
