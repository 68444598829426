import { Injectable } from '@angular/core';
import { BaseMockApi } from 'app/mock-api/base.api';
import { analytics as analyticsData } from 'app/mock-api/dashboards/analytics/analytics.data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AnalyticsMockApi extends BaseMockApi {
  private _analytics: any = analyticsData;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Sales - GET
    // -----------------------------------------------------------------------------------------------------
    this.fuseMockApiService.onGet('api/dashboards/analytics').reply(() => [200, cloneDeep(this._analytics)]);
  }
}
