import { FuseNavigationItem } from '@fuse/components/navigation';

export const homeItem: FuseNavigationItem = {
  id: 'dashboards',
  title: 'Dashboard',
  subtitle: 'Dashboard descrption',
  type: 'basic',
  icon: 'heroicons_outline:home',
  link: '/example'
};

export const responseNavigation = {
  code: 'OK',
  content: {
    aplicaciones: [
      {
        activa: true,
        codigo: 'COMERCIAL',
        fechaCreacion: '2019-11-22T16:01:01+0100',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf090b8d2f9b2b89bb61e4f2f86e74d1635',
        modulosErps: [
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR_VISITAS',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45830bb422afce9935c5e1110c8b5df912',
                nombre: 'Modificar Visitas'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'SHOW_CALENDAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4533f2b7f03e90d56d3a7ca5952deb9e56',
                nombre: 'Mostrar Calendario'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'EXCEL_CAL',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45d4b64dbbcca1bf2bf7276706924fd73e',
                nombre: 'Excel calendario'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ORDERS',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45046ef243ee5a5fa0544ac810afc2b8b8',
                nombre: 'Pedidos'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ADD_VISIT',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b452f8da34dedff0268108592ab1a781975',
                nombre: 'Añadir visitas'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'SHOW_REPORT',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4508813a54596168b00dcd95a43987a90e',
                nombre: 'Mostrar Reportes'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'COMERCIAL',
              fechaCreacion: '2019-11-22T16:01:01+0100',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf090b8d2f9b2b89bb61e4f2f86e74d1635',
              modulosErps: [],
              orden: 0,
              path: '/comercial',
              descripcion: 'Aplicación para uso del departamento comercial',
              nombre: 'Comercial'
            },
            codigo: 'GEST_PTOS_VTA',
            descripcion: 'Gestión de los puntos de venta de los clientes y las marcas',
            externo: false,
            idModuloAES: '89866477f961ff01b87a5ef0f2ea0b4f',
            navbar: false,
            nombre: 'Gestión de puntos de venta',
            orden: 0,
            path: '/comercial/puntos_de_venta',
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '4473cc42818c41615004a25972115e6b',
                nombre: 'Canarias',
                codigoInterno: 'CA',
                codigoJbase: '2',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '51e8967cd45a350bdd0edf51d0c92b09',
                nombre: 'Francia',
                codigoInterno: 'FR',
                codigoJbase: '3',
                type: 1,
                checkboxActivo: false,
                path: ''
              }
            ]
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'CLIENT_VIEW_MODE',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4529404043e0c76b3cf548d3b8b0ac3601',
                nombre: 'Vista Cliente'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'COMERCIAL',
              fechaCreacion: '2019-11-22T16:01:01+0100',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf090b8d2f9b2b89bb61e4f2f86e74d1635',
              modulosErps: [],
              orden: 0,
              path: '/comercial',
              descripcion: 'Aplicación para uso del departamento comercial',
              nombre: 'Comercial'
            },
            codigo: 'TVENTAS',
            descripcion: 'Terminal de Ventas v2',
            externo: true,
            idModuloAES: '59c3bf488d0672bbd8dc4ebd52b4e0b5e828dd1bafd2fef50fd75a91bac4f7f5',
            navbar: false,
            nombre: 'Terminal de Ventas v2',
            orden: 2,
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: 'https://salesb2b.festinagroup.com/'
              }
            ]
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'SUBIR_MAGUS',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45e9e95fcfd8ed64f6459edc83dac7b1e2',
                nombre: 'Subir a Magus'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'CHECK_PROPOSAL',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45d4bf8960abc7533ef46213724567b291',
                nombre: 'Revisar propuestas'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'COMERCIAL',
              fechaCreacion: '2019-11-22T16:01:01+0100',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf090b8d2f9b2b89bb61e4f2f86e74d1635',
              modulosErps: [],
              orden: 0,
              path: '/comercial',
              descripcion: 'Aplicación para uso del departamento comercial',
              nombre: 'Comercial'
            },
            codigo: 'PEDIDOS_TVENTAS',
            externo: false,
            idModuloAES: '4408eac6902ae27dc3f2784d9b44b02eef4c8836b5f91c0e2393ea059b885e48',
            navbar: false,
            nombre: 'Gestión Pedidos TVentas',
            orden: 3,
            path: '/comercial/gestionpedidoscomprarelojes',
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '4473cc42818c41615004a25972115e6b',
                nombre: 'Canarias',
                codigoInterno: 'CA',
                codigoJbase: '2',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '51e8967cd45a350bdd0edf51d0c92b09',
                nombre: 'Francia',
                codigoInterno: 'FR',
                codigoJbase: '3',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '30e774a51654920d629f5a51f04fa48c',
                nombre: 'Bélgica',
                codigoInterno: 'BE',
                codigoJbase: '4',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '38a4011695600e20d66a48247e70b862',
                nombre: 'Italia',
                codigoInterno: 'IT',
                codigoJbase: '5',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '00ac6bf8f65f873c747ec2acf6cb7125',
                nombre: 'Alemania',
                codigoInterno: 'DE',
                codigoJbase: '6',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: 'd33f095123f473e16e66aa5dcb72324e',
                nombre: 'Suiza',
                codigoInterno: 'SZ',
                codigoJbase: '7',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '346a9bfc2b23706e8dffc38947e981d7',
                nombre: 'Portugal',
                codigoInterno: 'PT',
                codigoJbase: '20',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '9cda30e48db3a2dcd43660f95227fb07',
                nombre: 'Suiza H5',
                codigoInterno: 'H5',
                codigoJbase: '7',
                type: 1,
                checkboxActivo: false,
                path: ''
              }
            ]
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'GESTOR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4545f44f185af39e1e9950270ab53147fb',
                nombre: 'Gestor'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'COMERCIAL',
              fechaCreacion: '2019-11-22T16:01:01+0100',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf090b8d2f9b2b89bb61e4f2f86e74d1635',
              modulosErps: [],
              orden: 0,
              path: '/comercial',
              descripcion: 'Aplicación para uso del departamento comercial',
              nombre: 'Comercial'
            },
            codigo: 'ARTWORK_ORDERS',
            externo: false,
            idModuloAES: '392fd105af04aa9eb2a9d8ea7387f0f0fd75ff49a8d16df73e39108a0b47ef50',
            navbar: false,
            nombre: 'Gestion de pedidos graficas',
            orden: 4,
            path: '/comercial/graphics-orders',
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: ''
              },
              {
                idSedeAES: '346a9bfc2b23706e8dffc38947e981d7',
                nombre: 'Portugal',
                codigoInterno: 'PT',
                codigoJbase: '20',
                type: 1,
                checkboxActivo: false,
                path: ''
              }
            ]
          }
        ],
        orden: 1,
        path: '/comercial',
        descripcion: 'Aplicación para uso del departamento comercial',
        nombre: 'Comercial'
      },
      {
        activa: true,
        codigo: 'INFORMATICA',
        fechaCreacion: '2019-04-08T15:04:22+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf0730b87975e48d9f09de29ddde9d1b315',
        modulosErps: [
          {
            acciones: [],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'INFORMATICA',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf0730b87975e48d9f09de29ddde9d1b315',
              modulosErps: [],
              orden: 1,
              path: '/informatica',
              nombre: 'Informática'
            },
            codigo: 'TAREAS',
            descripcion: 'Gestión de las tareas programadas',
            externo: false,
            idModuloAES: 'eea66ba0e0c466f02a1575913549b59e',
            navbar: false,
            nombre: 'Gestión de tareas',
            orden: 0,
            path: '/informatica/tareas',
            sedes: []
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'INFORMATICA',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf0730b87975e48d9f09de29ddde9d1b315',
              modulosErps: [],
              orden: 1,
              path: '/informatica',
              nombre: 'Informática'
            },
            codigo: 'TAREA-GRUPOS',
            descripcion: 'Grupos de tareas',
            externo: false,
            idModuloAES: '928f2e2d0a467eb6a3ef42b48656ba20',
            navbar: false,
            nombre: 'Grupos de tareas',
            orden: 0,
            path: '/informatica/tarea-grupos',
            sedes: []
          }
        ],
        orden: 2,
        path: '/informatica',
        nombre: 'Informática'
      },
      {
        activa: true,
        codigo: 'TIENDAONLINE',
        fechaCreacion: '2019-04-08T15:04:22+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf039c000cd2b454192671157d7e817ecf2',
        modulosErps: [
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAONLINE',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf039c000cd2b454192671157d7e817ecf2',
              modulosErps: [],
              orden: 3,
              path: '/tiendaonline',
              nombre: 'eCommerces Tools'
            },
            codigo: 'INCIDENCIAS',
            descripcion: '',
            externo: false,
            idModuloAES: '28aa064f81fa8270c1035f78d08b48b1',
            navbar: false,
            nombre: 'Atención al cliente',
            orden: 2,
            path: '/tiendaonline/incidencias',
            sedes: []
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR_PROMOCION',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45e48ababf1121b92983b60833e3a8dcc3',
                nombre: 'Añadir promocion'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAONLINE',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf039c000cd2b454192671157d7e817ecf2',
              modulosErps: [],
              orden: 3,
              path: '/tiendaonline',
              nombre: 'eCommerces Tools'
            },
            codigo: 'CESTA',
            externo: false,
            idModuloAES: 'cccd3d597d939fc3f41a1f6132a516ef',
            navbar: false,
            nombre: 'Cestas Online',
            orden: 3,
            path: '/tiendaonline/cestas',
            sedes: []
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAONLINE',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf039c000cd2b454192671157d7e817ecf2',
              modulosErps: [],
              orden: 3,
              path: '/tiendaonline',
              nombre: 'eCommerces Tools'
            },
            codigo: 'PEDIDOS',
            externo: false,
            idModuloAES: '7b1a382bddb8c9a1d751e24d130f0f32',
            navbar: false,
            nombre: 'Gestión Pedidos Online',
            orden: 4,
            path: '/tiendaonline/pedidos',
            sedes: []
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAONLINE',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf039c000cd2b454192671157d7e817ecf2',
              modulosErps: [],
              orden: 3,
              path: '/tiendaonline',
              nombre: 'eCommerces Tools'
            },
            codigo: 'COLECCIONES',
            descripcion: 'Gestión de colecciones del ERP de Festina',
            externo: false,
            idModuloAES: '0467113fdf6ff39162cb477d48e0b7ec',
            navbar: false,
            nombre: 'Colecciones Online',
            orden: 7,
            path: '/tiendaonline/colecciones',
            sedes: []
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'EXCEL_FIC_PROD',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b457f8e4735c2c9b7098ad802ec9bb086c8',
                nombre: 'Excel ficha prod.'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'ACTIVA_FAMILIA_PROD',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b456a063e6594ccbfa3a253bba5487a7391',
                nombre: 'Activ. prod. família'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'EXCEL_SIT_IDIOMAS',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45a72cc1389a736de48d5ebe0d5b1b6953',
                nombre: 'Excel sit. idiomas'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'GES_REF_CRUZ',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45caf720fcf2f2d2b0ba8242506e4a1f3d',
                nombre: 'Gestión ref.cruz.'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'CALC_IMG_PROD',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b450bbab5f3a8b0a86896d34fa255f2881e',
                nombre: 'Calcular imágenes'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'GEN_CATALOGO_ECI',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4561cfc58e8172d8fdb9a2456bf2ef1979',
                nombre: 'Generar catálogo ECI'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'MIRAKL',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4511fd819d3225ea748791f03e4c370c36',
                nombre: 'Enviar oferta mirakl'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'DEL_IMG_PROD',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b456e3f202da8c2f29ae0cb0ad625cff3aa',
                nombre: 'Eliminar imágenes'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'AMAZON_PRO',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4565f848cfbb3ca800fb25ec6747a9f45a',
                nombre: 'Act. Amazon'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'BULLET_POINT_PRO',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45988d06e1e28a7deb755e0c6f16bf4f40',
                nombre: 'Act. Viñetas'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'UPLOAD_EXCEL_AMAZON',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45d138bab9a0a733719903cfa07bc246cb',
                nombre: 'Excel Amazon'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'UPLOAD_EXCEL_ARISE',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4596a20ac48fb1bf8ae295dfbfac1cd9d1',
                nombre: 'Excel Arise'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'UPDATE_PRICE_AMAZON',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4520d75de2e2b36cf826351142f45cfe68',
                nombre: 'Amazon sale prices'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAONLINE',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf039c000cd2b454192671157d7e817ecf2',
              modulosErps: [],
              orden: 3,
              path: '/tiendaonline',
              nombre: 'eCommerces Tools'
            },
            codigo: 'PRODUCTOS',
            descripcion: 'Gestion de productos online del ERP de Festina',
            externo: false,
            idModuloAES: '0e2d069cdc2e0ba3a98662d3e751cd59',
            navbar: false,
            nombre: 'Productos Online',
            orden: 8,
            path: '/tiendaonline/productos',
            sedes: []
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAONLINE',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf039c000cd2b454192671157d7e817ecf2',
              modulosErps: [],
              orden: 3,
              path: '/tiendaonline',
              nombre: 'eCommerces Tools'
            },
            codigo: 'DEVOL_ALMACEN',
            descripcion: 'Devolución de los pedidos con destino almacén generados en la tienda online y devoluciones de stock',
            externo: false,
            idModuloAES: '2441eceb0b022f471eb6234ef686fb44291125940b2f5128a956a6c9d21c6bc4',
            navbar: false,
            nombre: 'eCommerce  - Devoluciones en Almacen',
            orden: 10,
            path: '/tiendas/devoluciones_almacen',
            sedes: []
          }
        ],
        orden: 3,
        path: '/tiendaonline',
        nombre: 'eCommerces Tools'
      },
      {
        activa: true,
        codigo: 'LOGISTICA',
        fechaCreacion: '2019-04-08T15:04:22+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf06842490ff91af7ce1e1b8e3b1c67f4f2',
        modulosErps: [
          {
            acciones: [],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'LOGISTICA',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf06842490ff91af7ce1e1b8e3b1c67f4f2',
              modulosErps: [],
              orden: 5,
              path: '/logistica',
              nombre: 'Logistica'
            },
            codigo: 'DEVOL',
            externo: true,
            idModuloAES: '5911c20979580109e442fd581ca2e034',
            navbar: false,
            nombre: 'Devolución tiendas',
            orden: 0,
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.86:8080/devolucion-entre-tiendas-war/Login.wg'
              }
            ]
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'LOGISTICA',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf06842490ff91af7ce1e1b8e3b1c67f4f2',
              modulosErps: [],
              orden: 5,
              path: '/logistica',
              nombre: 'Logistica'
            },
            codigo: 'RFD_BTW_PHYS_STORES',
            descripcion: 'Devoluciones entre tiendas',
            externo: false,
            idModuloAES: '4f76765f401dbbbe4eb7e0440e79db1cb6f2d16299626e8732e43f904c73ca54',
            navbar: false,
            nombre: 'Devoluciones entre tiendas',
            orden: 5,
            path: '/logistica/refunds-between-physical-stores',
            sedes: []
          }
        ],
        orden: 4,
        path: '/logistica',
        nombre: 'Logistica'
      },
      {
        activa: true,
        codigo: 'TIENDAS',
        fechaCreacion: '2019-04-08T15:04:22+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf09ed02b02d492192d2a612ce50b2cb6bc',
        modulosErps: [
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'BORRAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b453b7a6b3ea2a4becd6bdfb8b68803851b',
                nombre: 'Borrar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAS',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf09ed02b02d492192d2a612ce50b2cb6bc',
              modulosErps: [],
              orden: 7,
              path: '/tiendas',
              nombre: 'Tiendas'
            },
            codigo: 'UTILS_COORDINADOR',
            descripcion: 'Útiles Coordinador',
            externo: false,
            idModuloAES: '7cdfa45d894c889a1c11b3beab28362372af4c46e6752f45d9c4cb409218835f',
            navbar: false,
            nombre: 'Útiles Coordinador',
            orden: 0,
            path: '/tiendas/utils-coordinador',
            sedes: []
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAS',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf09ed02b02d492192d2a612ce50b2cb6bc',
              modulosErps: [],
              orden: 7,
              path: '/tiendas',
              nombre: 'Tiendas'
            },
            codigo: 'PHYSICAL_STORE_MNG',
            descripcion: 'Gestión de tiendas físicas',
            externo: false,
            idModuloAES: 'b38708d7b7fb56a6ea1bbad153708596e0696c7c19932c04af6322699b2bf6c7',
            navbar: false,
            nombre: 'Gestión de tiendas físicas',
            orden: 1,
            path: '/tiendas/gestion_tiendas_fisicas',
            sedes: []
          },
          {
            acciones: [],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAS',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf09ed02b02d492192d2a612ce50b2cb6bc',
              modulosErps: [],
              orden: 7,
              path: '/tiendas',
              nombre: 'Tiendas'
            },
            codigo: 'DEVOLUCIONES_TIENDAS',
            externo: false,
            idModuloAES: '8761109d8f6e714fa660a695140ad7f7',
            navbar: false,
            nombre: 'eCommerce - Devoluciones en Tienda',
            orden: 2,
            path: '/tiendas/devoluciones_tiendas',
            sedes: []
          },
          {
            acciones: [],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAS',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf09ed02b02d492192d2a612ce50b2cb6bc',
              modulosErps: [],
              orden: 7,
              path: '/tiendas',
              nombre: 'Tiendas'
            },
            codigo: 'GEST_MAN',
            externo: true,
            idModuloAES: '2782a16b6dce46bf538d61868feb49e7',
            navbar: false,
            nombre: 'Gestina Management',
            orden: 11,
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8081/gestina-management-war/Login.wg'
              }
            ]
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TIENDAS',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf09ed02b02d492192d2a612ce50b2cb6bc',
              modulosErps: [],
              orden: 7,
              path: '/tiendas',
              nombre: 'Tiendas'
            },
            codigo: 'COMPRA_ONLINE',
            descripcion: 'Compra Online',
            externo: false,
            idModuloAES: 'a4fda7eb2f4b78961b828c68db06eb7e4ff73d8a10e8c80d785fa85dde5bf0e1',
            navbar: false,
            nombre: 'Compra Online',
            orden: 14,
            path: '/tiendas/compra-online',
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false
              }
            ]
          }
        ],
        orden: 5,
        path: '/tiendas',
        nombre: 'Tiendas'
      },
      {
        activa: true,
        codigo: 'ADMINISTRACION',
        fechaCreacion: '2019-04-08T15:04:22+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf05f2bdd4159014d76e4b40f39e7c50a1a',
        modulosErps: [
          {
            acciones: [],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'ADMINISTRACION',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf05f2bdd4159014d76e4b40f39e7c50a1a',
              modulosErps: [],
              orden: 9,
              path: '/administracion',
              nombre: 'Gestión Platform'
            },
            codigo: 'USUARIOS',
            externo: false,
            idModuloAES: '17ab7551689b6bfe35d44e2cd5cf6c64',
            navbar: false,
            nombre: 'Usuarios Platform',
            orden: 0,
            path: '/administracion/usuarios',
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: ''
              }
            ]
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'ADMINISTRACION',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf05f2bdd4159014d76e4b40f39e7c50a1a',
              modulosErps: [],
              orden: 9,
              path: '/administracion',
              nombre: 'Gestión Platform'
            },
            codigo: 'PLANTILLAS',
            descripcion: 'Gestión de plantilas del ERP de Festina',
            externo: false,
            idModuloAES: '701bb6dfdf854bff8bc5f4b32c96d5a0',
            navbar: false,
            nombre: 'Plantillas',
            orden: 1,
            path: '/administracion/plantillas',
            sedes: []
          }
        ],
        orden: 6,
        path: '/administracion',
        nombre: 'Gestión Platform'
      },
      {
        activa: true,
        codigo: 'PRODUCTO',
        fechaCreacion: '2019-04-08T15:04:22+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf07a3d5822d5011a5b8dab014727d40ac2',
        modulosErps: [
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'PRODUCTO',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf07a3d5822d5011a5b8dab014727d40ac2',
              modulosErps: [],
              orden: 13,
              path: '/app_producto',
              nombre: 'Producto'
            },
            codigo: 'SIMPCAT',
            externo: true,
            idModuloAES: '49bd332afbb0d0b3099e7a58344b2203',
            navbar: false,
            nombre: 'Catálogo productos',
            orden: 5,
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.58:8080/product-catalog-war/Login.wg'
              }
            ]
          }
        ],
        orden: 7,
        path: '/app_producto',
        nombre: 'Producto'
      },
      {
        activa: true,
        codigo: 'COMPRAS',
        fechaCreacion: '2019-04-08T15:04:22+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf0f1974323ab70273506d339da7d8a7f25',
        modulosErps: [
          {
            acciones: [],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'COMPRAS',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf0f1974323ab70273506d339da7d8a7f25',
              modulosErps: [],
              orden: 8,
              path: '/compras',
              nombre: 'Compras'
            },
            codigo: 'NEW_COMPREL',
            externo: true,
            idModuloAES: '10218980d068bdf34eb785f1c275101d',
            navbar: false,
            nombre: 'Compra de Relojes',
            orden: 0,
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: 'https://nuevacomprarelojes.festina.com/compra-relojes-war/Login.wg'
              },
              {
                idSedeAES: '4473cc42818c41615004a25972115e6b',
                nombre: 'Canarias',
                codigoInterno: 'CA',
                codigoJbase: '2',
                type: 1,
                checkboxActivo: false,
                path: 'https://nuevacomprarelojes.festina.com/compra-relojes-war/Login.wg'
              },
              {
                idSedeAES: '51e8967cd45a350bdd0edf51d0c92b09',
                nombre: 'Francia',
                codigoInterno: 'FR',
                codigoJbase: '3',
                type: 1,
                checkboxActivo: false,
                path: 'https://salesb2b.festinagroup.com/'
              },
              {
                idSedeAES: '30e774a51654920d629f5a51f04fa48c',
                nombre: 'Bélgica',
                codigoInterno: 'BE',
                codigoJbase: '4',
                type: 1,
                checkboxActivo: false,
                path: 'https://nuevacomprarelojes.festina.com/compra-relojes-war/Login.wg'
              },
              {
                idSedeAES: '38a4011695600e20d66a48247e70b862',
                nombre: 'Italia',
                codigoInterno: 'IT',
                codigoJbase: '5',
                type: 1,
                checkboxActivo: false,
                path: 'https://nuevacomprarelojes.festina.com/compra-relojes-war/Login.wg'
              },
              {
                idSedeAES: '00ac6bf8f65f873c747ec2acf6cb7125',
                nombre: 'Alemania',
                codigoInterno: 'DE',
                codigoJbase: '6',
                type: 1,
                checkboxActivo: false,
                path: 'https://nuevacomprarelojes.festina.com/compra-relojes-war/Login.wg'
              },
              {
                idSedeAES: 'd33f095123f473e16e66aa5dcb72324e',
                nombre: 'Suiza',
                codigoInterno: 'SZ',
                codigoJbase: '7',
                type: 1,
                checkboxActivo: false,
                path: 'https://nuevacomprarelojes.festina.com/compra-relojes-war/Login.wg'
              }
            ]
          },
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'COMPRAS',
              fechaCreacion: '2019-04-08T15:04:22+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf0f1974323ab70273506d339da7d8a7f25',
              modulosErps: [],
              orden: 8,
              path: '/compras',
              nombre: 'Compras'
            },
            codigo: 'PED_CR',
            externo: true,
            idModuloAES: '7fd083091ec35ebf7655fec460b07e5f',
            navbar: false,
            nombre: 'Gestión pedidos Compra relojes',
            orden: 1,
            sedes: [
              {
                idSedeAES: 'b68635ad2f54d01989f895e36605689c',
                nombre: 'España',
                codigoInterno: 'ES',
                codigoJbase: '1',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8080/compra-relojes-pedidos-war/Login.wg'
              },
              {
                idSedeAES: '4473cc42818c41615004a25972115e6b',
                nombre: 'Canarias',
                codigoInterno: 'CA',
                codigoJbase: '2',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8080/compra-relojes-pedidos-war/Login.wg'
              },
              {
                idSedeAES: '51e8967cd45a350bdd0edf51d0c92b09',
                nombre: 'Francia',
                codigoInterno: 'FR',
                codigoJbase: '3',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8080/compra-relojes-pedidos-war/Login.wg'
              },
              {
                idSedeAES: '30e774a51654920d629f5a51f04fa48c',
                nombre: 'Bélgica',
                codigoInterno: 'BE',
                codigoJbase: '4',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8080/compra-relojes-pedidos-war/Login.wg'
              },
              {
                idSedeAES: '38a4011695600e20d66a48247e70b862',
                nombre: 'Italia',
                codigoInterno: 'IT',
                codigoJbase: '5',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8080/compra-relojes-pedidos-war/Login.wg'
              },
              {
                idSedeAES: '00ac6bf8f65f873c747ec2acf6cb7125',
                nombre: 'Alemania',
                codigoInterno: 'DE',
                codigoJbase: '6',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8080/compra-relojes-pedidos-war/Login.wg'
              },
              {
                idSedeAES: 'd33f095123f473e16e66aa5dcb72324e',
                nombre: 'Suiza',
                codigoInterno: 'SZ',
                codigoJbase: '7',
                type: 1,
                checkboxActivo: false,
                path: 'http://10.1.2.83:8080/compra-relojes-pedidos-war/Login.wg'
              }
            ]
          }
        ],
        orden: 8,
        path: '/compras',
        nombre: 'Compras'
      },
      {
        activa: true,
        codigo: 'TOOLS',
        fechaCreacion: '2022-03-28T14:24:04+0200',
        idAplicacionAES: '71514fac1b71da0d7038892648d35cf0b8c5004a4c0adc607ee22b12f0bf253a',
        modulosErps: [
          {
            acciones: [
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'ANADIR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579c9cb702f9726997687805a80e72637',
                nombre: 'Añadir'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'MODIFICAR',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4560580d0a192c6e415c929db57ec1eae1',
                nombre: 'Modificar'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'DES_IMG_PROD',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4579373f2f2b68cc43ca693cfcd7f40f24',
                nombre: 'Descarga imágenes'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'LIST-WITH-IMG-REF',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b45b6a2b77652d41828fd25d769714c4add',
                nombre: 'Listado img & ref'
              },
              {
                activa: false,
                checkboxActivo: false,
                codigo: 'ECI-CATALOG-106',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b452e694ed32abe77b9ece45b9588f2e0fa',
                nombre: 'Catalogo ECI 106'
              },
              {
                activa: true,
                checkboxActivo: false,
                codigo: 'EXCEL-PED-PROD',
                idAccion: 'e60f7891c0cc45ff49b1511447a01b4500e5e16a377ec6a7a0845e4812153f86',
                nombre: 'Excel pedido prod'
              }
            ],
            activo: true,
            aplicacion: {
              activa: true,
              codigo: 'TOOLS',
              fechaCreacion: '2022-03-28T14:24:04+0200',
              idAplicacionAES: '71514fac1b71da0d7038892648d35cf0b8c5004a4c0adc607ee22b12f0bf253a',
              modulosErps: [],
              orden: 18,
              path: '/tools',
              descripcion: 'Herramientas',
              nombre: 'Herramientas'
            },
            codigo: 'TOOLS',
            descripcion: 'Herramientas',
            externo: false,
            idModuloAES: 'db7f03aa6bf7364d674a96e23417326f9a1a5677161afab2331e456ef63662f6',
            navbar: false,
            nombre: 'Herramientas',
            orden: 0,
            path: '/tools/tools',
            sedes: []
          }
        ],
        orden: 9,
        path: '/tools',
        descripcion: 'Herramientas',
        nombre: 'Herramientas'
      }
    ],
    usuario: {
      accesoExterno: false,
      activo: true,
      apellidos: 'Arnez Andrae',
      autenticaPorEmail: false,
      codigoCliente: '',
      codigoIdioma: 'es-ES',
      contrasenya: 'c93ccd78b2076528346216b3b2f701e6',
      contrasenyaSegura: false,
      controlCambioEmail: false,
      copiarModulos: false,
      email: 'earnez@festina.com',
      emailValido: false,
      fullName: 'Eynar Arnez Andrae',
      idUsuario: '6599fda53f693dc39ec169886a1650867308d87b86eddff44cb18d9128baeb3a',
      imagen:
        'https://media6.festinagroup.com/static/usuarios/perfil/6599fda53f693dc39ec169886a1650867308d87b86eddff44cb18d9128baeb3a.jpeg',
      nombre: 'Eynar',
      perfil: {
        codigoInterno: 'INF',
        idPerfilAES: '2439f66843bab990c34fd19931e5aa675f079fb143ce6a199dc6e18305a0303e',
        nombre: 'Informática'
      },
      sede: {
        idSedeAES: 'b68635ad2f54d01989f895e36605689c',
        nombre: 'España',
        codigoInterno: 'ES',
        codigoJbase: '1',
        conexionJbase: 'Barcelona',
        cuentaJbaseNet: 'ORO_NET',
        type: 1
      },
      solicitudCambioEmail: false,
      ultimoAcceso: '2024-05-10T12:57:59+0200',
      userUuid: 'a864dac3-811f-11ed-8779-0ab26a452fcd',
      usuario: 'earnez@festina.com'
    }
  }
};
