import { Injectable } from '@angular/core';
import { IAuthenticate } from 'app/shared/models/authenticate';
import { IResponse } from 'app/shared/models/response';
import { IUser } from 'app/shared/models/user';
import { BaseService } from 'app/shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  getUsuario(idUsuario: string): Observable<IResponse> {
    return this._httpClient.get<IResponse>(`${this.apiPublicUrl}/admin/usuarios/${idUsuario}`);
  }

  editUser(idUsuario: string, user: Partial<IUser>): Observable<IResponse> {
    return this._httpClient.put<IResponse>(`${this.apiPublicUrl}/admin/usuarios/${idUsuario}`, user);
  }

  addPhoneNumber2FA(phoneNumber: string): Observable<IResponse> {
    return this._httpClient.put<IResponse>(`${this.apiPublicUrl}/admin/two-factor-auth/update-phone?phoneNumber=${phoneNumber}`, {});
  }

  generate2FACode(): Observable<IResponse> {
    return this._httpClient.put<IResponse>(`${this.apiPublicUrl}/admin/two-factor-auth/generate-and-send-short-code`, {});
  }

  verify2FACode(shortCode: string): Observable<IResponse> {
    return this._httpClient.put<IResponse>(`${this.apiPublicUrl}/admin/two-factor-auth/verify-short-code?shortCode=${shortCode}`, {});
  }

  autologin(token: string): Observable<IAuthenticate> {
    return this._httpClient.post<IAuthenticate>(`${this.apiPrivateUrl}/admin/usuarios/autologin`, token);
  }
}
