import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, HostBinding, OnInit, Renderer2, effect, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseConfig } from '@fuse/services/config';
import { FusePlatformService } from '@fuse/services/platform';
import { FUSE_VERSION } from '@fuse/version';
import { Store } from '@ngrx/store';
import { fromNavigation } from 'app/core/navigation/+state/navigation.reducer';
import { fromUser } from 'app/core/user/+state/user.reducer';
import { fromSettings } from './common/settings/+state/settings.reducer';
import { EmptyLayoutComponent } from './layouts/empty/empty.component';
import { CenteredLayoutComponent } from './layouts/horizontal/centered/centered.component';
import { EnterpriseLayoutComponent } from './layouts/horizontal/enterprise/enterprise.component';
import { MaterialLayoutComponent } from './layouts/horizontal/material/material.component';
import { ModernLayoutComponent } from './layouts/horizontal/modern/modern.component';
import { ClassicLayoutComponent } from './layouts/vertical/classic/classic.component';
import { ClassyLayoutComponent } from './layouts/vertical/classy/classy.component';
import { CompactLayoutComponent } from './layouts/vertical/compact/compact.component';
import { DenseLayoutComponent } from './layouts/vertical/dense/dense.component';
import { FuturisticLayoutComponent } from './layouts/vertical/futuristic/futuristic.component';
import { ThinLayoutComponent } from './layouts/vertical/thin/thin.component';

@Component({
  standalone: true,
  selector: 'layout',
  templateUrl: './layout.component.html',
  imports: [
    EmptyLayoutComponent,
    CenteredLayoutComponent,
    EnterpriseLayoutComponent,
    MaterialLayoutComponent,
    ModernLayoutComponent,
    ClassicLayoutComponent,
    ClassyLayoutComponent,
    CompactLayoutComponent,
    DenseLayoutComponent,
    FuturisticLayoutComponent,
    ThinLayoutComponent
  ]
})
export class LayoutComponent implements OnInit {
  @HostBinding('class') classes = 'flex flex-auto w-full max-w-full min-w-0';
  private document = inject(DOCUMENT);
  private activatedRoute = inject(ActivatedRoute);
  private renderer2 = inject(Renderer2);
  private fusePlatformService = inject(FusePlatformService);
  config: FuseConfig;
  layout: string;
  scheme: 'dark' | 'light';
  theme: string;
  store = inject(Store);
  theme$ = this.store.selectSignal(fromSettings.selectTheme);
  scheme$ = this.store.selectSignal(fromSettings.selectScheme);
  layout$ = this.store.selectSignal(fromSettings.selectLayout);
  navigation$ = this.store.selectSignal(fromNavigation.selectNavigation);
  user$ = this.store.selectSignal(fromUser.selectUser);
  private fuseNavigationService = inject(FuseNavigationService);
  isScreenSmall: boolean;
  readonly destroyRef = inject(DestroyRef);

  layoutEffect = effect(() => {
    if (this.theme$()) {
      this.updateLayout();
      this.updateScheme();
      this.updateTheme();
    }
  });

  ngOnInit(): void {
    this.renderer2.setAttribute(this.document.querySelector('[ng-version]'), 'fuse-version', FUSE_VERSION);
    this.renderer2.addClass(this.document.body, this.fusePlatformService.osName);
  }

  private updateLayout(): void {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    this.layout = this.layout$();
    const paths = route.pathFromRoot;
    paths.forEach(path => {
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
        this.layout = path.routeConfig.data.layout;
      }
    });
  }

  private updateScheme(): void {
    this.document.body.classList.remove('light', 'dark');
    this.document.body.classList.add(this.scheme$());
  }

  private updateTheme(): void {
    this.document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this.document.body.classList.remove(className, className.split('-')[1]);
      }
    });
    this.document.body.classList.add(this.theme$());
  }

  toggleNavigation(name): void {
    const navigation = this.fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
    if (navigation) {
      navigation.toggle();
    }
  }
}
