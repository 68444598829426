import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromAuth } from '../data-access/+state/auth.reducer';

export const AuthTwoFactorGuard: CanActivateFn | CanActivateChildFn = (_route, state) => {
  const router = inject(Router);
  const store = inject(Store);
  const accessToken$ = store.selectSignal(fromAuth.selectAccessToken);

  if (!accessToken$()) {
    const redirectURL = state.url === '/sign-out' || state.url.includes('/unlock-session') ? '' : `redirectURL=${state.url}`;
    return router.parseUrl(`/sign-in?${redirectURL}`);
  }

  return true;
};
