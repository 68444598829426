<fuse-loading-bar />

<div class="flex w-full flex-auto justify-center bg-gray-200 dark:bg-card sm:p-4 md:p-8">
  @if (isScreenSmall) {
    <fuse-vertical-navigation
      class="dark bg-gray-900 print:hidden"
      [mode]="'over'"
      [name]="'mainNavigation'"
      [navigation]="navigation?.default"
      [opened]="false"
    >
      <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex h-20 items-center px-8 pt-6">
          <img class="w-30" src="assets/images/logo/perrelet.png" />
        </div>
      </ng-container>
    </fuse-vertical-navigation>
  }

  <div class="flex min-w-0 max-w-360 flex-auto flex-col items-center overflow-hidden shadow-2xl dark:shadow-none sm:rounded-xl">
    <div class="bg-card relative z-49 flex h-16 w-full flex-0 items-center border-b px-4 dark:bg-default sm:h-20 md:px-6 print:hidden">
      @if (!isScreenSmall) {
        <div class="mx-2 flex items-center lg:mr-8">
          <div class="hidden lg:flex">
            <img class="w-24 dark:hidden" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
            <img class="hidden w-24 dark:flex" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
          </div>
          <img class="flex w-8 lg:hidden" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
        </div>
        <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation?.horizontal" />
      }
      @if (isScreenSmall) {
        <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
          <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
      }
      <div class="ml-auto flex items-center space-x-1 pl-2 sm:space-x-2">
        <languages />
        <fuse-fullscreen class="hidden md:block" />
        <search [appearance]="'bar'" />
        <user />
      </div>
    </div>

    <div class="bg-default flex w-full flex-auto flex-col">
      <router-outlet />
    </div>

    <div
      class="bg-card relative z-49 flex h-16 w-full flex-0 items-center justify-start border-t px-6 dark:bg-default sm:h-20 sm:px-8 print:hidden"
    >
      <span class="text-secondary font-medium">Perrelet &copy; {{ currentYear }}</span>
    </div>
  </div>
</div>
