import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromAuth } from '../data-access/+state/auth.reducer';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
  const router = inject(Router);
  const store = inject(Store);
  const authenticate$ = store.selectSignal(fromAuth.isAuthenticated);

  return authenticate$() ? router.parseUrl('') : true;
};
