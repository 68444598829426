import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { catchError, map, of, switchMap } from 'rxjs';
import { ReCaptchaService } from '../re-captcha.service';
import { RecaptchaActions } from './recaptcha.actions';

@Injectable()
export class RecaptchaEffects {
  private actions$ = inject(Actions);
  private recaptchaV3Service = inject(ReCaptchaV3Service);
  private recaptchaService = inject(ReCaptchaService);

  doAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecaptchaActions.doAction),
      switchMap(({ action }) => {
        return this.recaptchaV3Service.execute(action).pipe(
          map(token => {
            return RecaptchaActions.sendRecaptcha({ token, action });
          }),
          catchError(err => {
            return of(
              RecaptchaActions.doActionFailure({
                errorCode: err?.error?.status,
                errorMessage: err?.error?.message
              })
            );
          })
        );
      })
    )
  );

  sendRecaptcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecaptchaActions.sendRecaptcha),
      switchMap(({ token }) => {
        return this.recaptchaService.sendReCaptcha(token).pipe(
          map(response => {
            return RecaptchaActions.sendRecaptchaSuccess({ verified: response.content });
          }),
          catchError(err => {
            return of(
              RecaptchaActions.doActionFailure({
                errorCode: err?.error?.status,
                errorMessage: err?.error?.message
              })
            );
          })
        );
      })
    )
  );
}
