import { Injectable } from '@angular/core';
import { BaseMockApi } from 'app/mock-api/base.api';
import { user as userData } from 'app/mock-api/common/user/user.data';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AuthMockApi extends BaseMockApi {
  private readonly _secret = 'YOUR_VERY_CONFIDENTIAL_SECRET_FOR_SIGNING_JWT_TOKENS!!!';
  private readonly javiToken =
    'eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJGRVNUSU5BIEdST1VQIFMuQS4iLCJhdWQiOiJGRVNUSU5BIENMSUVOVCIsImV4cCI6MTcxNTQ2MzM3OSwianRpIjoiOWFIbko5MlBwNmxUYTJER1kxcDNKdyIsImlhdCI6MTcxNTI0NzM3OSwic3ViIjoiYXV0aGVudGljYXRpb24gVG9rZW4iLCJzZWRlIjoiYjY4NjM1YWQyZjU0ZDAxOTg5Zjg5NWUzNjYwNTY4OWMiLCJwcm9maWxlIjoiSU5GIiwidXNlclV1aWQiOiJhODYxOGI2My04MTFmLTExZWQtODc3OS0wYWIyNmE0NTJmY2QiLCJvcmlnZW5QZXRpY2lvbiI6IkJhY2tPZmZpY2UiLCJpZGlvbWEiOiJlcy1FUyIsInVzZXIiOiI1NDk0OWNhNTk2OTMwZjgzZWYxYjBiMmM2YzhmZTVhZDMzZTdmYjdlODE4ZWIwNmQ3NDQwY2EzMzc3NDA0ZjdiIiwiZW1haWwiOiJqcGludG9AZmVzdGluYS5jb20ifQ.SRy_Y7BMTRybbcs4RnpM2LPeG8_5k0qcB8tfaca5XVxPRGvWunNKJ6H7MPeI_5kBsrzD4UD9Q9zllEylH2e1WqgGHoTd7EGLL-gET5rPEsdz99po3O7zo2ZsliQN9C8X_l7E-a-WnFbiMfAaZMjoE1mGKGUJjHjBnGXL5d9MgQ4VUVKxRsmThb5gbUA5IT9VUyQxvrUVbOuMfZ2lKX0jxveG47xfyOa4acGXHvzpAr3QIQL6f1TXp4Iw2snzgoV3mf-AGcQ_fmnGBSa9bn70dmAp8-8hpSk_ISMwSQ0bWZDU8ClN5sR6GkvNlsJ2bN_p2JW0vDyLCSehZY6d6QShTA';
  private _user: any = userData;

  registerHandlers(): void {
    const javiId = '54949ca596930f83ef1b0b2c6c8fe5ad33e7fb7e818eb06d7440ca3377404f7b';

    this.fuseMockApiService.onGet(`${this.apiPublicUrl}/admin/usuarios/crear_enlace`, 1000).reply(() => [200, true]);

    this.fuseMockApiService.onPost(`${this.apiPublicUrl}/admin/usuarios/${javiId}`, 1000).reply(() => [200, true]);

    this.fuseMockApiService.onPost(`${this.apiPublicUrl}/admin/usuarios/authenticate`, 1500).reply(({ request }) => {
      // Sign in successful
      if (request.body.usuario === 'admin' && request.body.contrasenya === 'admin') {
        return [
          200,
          {
            status: 'success',
            privateKey: this.javiToken
          }
        ];
      }

      return [
        200,
        {
          expiresIn: 0,
          message: 'USR05',
          status: 'not authenticated'
        }
      ];
    });

    this.fuseMockApiService.onPost(`${this.apiPublicUrl}/auth/sign-in-with-token`).reply(({ request }) => {
      const accessToken = request.body.accessToken;

      if (this._verifyJWTToken(accessToken)) {
        return [
          200,
          {
            user: cloneDeep(this._user),
            accessToken: this.javiToken,
            tokenType: 'bearer'
          }
        ];
      }

      return [
        401,
        {
          error: 'Invalid token'
        }
      ];
    });

    this.fuseMockApiService.onPost(`${this.apiPublicUrl}/auth/sign-up`, 1500).reply(() => {
      return [200, { user: cloneDeep(this._user), privateKey: this.javiToken }];
    });

    this.fuseMockApiService.onPost('api/auth/unlock-session', 1500).reply(({ request }) => {
      if (request.body.email === 'hughes.brian@company.com' && request.body.password === 'admin') {
        return [
          200,
          {
            user: cloneDeep(this._user),
            accessToken: this.javiToken,
            tokenType: 'bearer'
          }
        ];
      }

      return [404, false];
    });

    this.fuseMockApiService.onPost(`${this.apiPublicUrl}/admin/usuarios/cambiar_contrasenya_acceso`, 1500).reply(({ urlParams }) => {
      if (urlParams['token'] === '12345678' && urlParams['nuevaContrasenya']) {
        return [
          200,
          {
            status: 'success'
          }
        ];
      }

      return [
        200,
        {
          expiresIn: 0,
          message: 'USR05'
        }
      ];
    });

    this.fuseMockApiService.onPut(`${this.apiPublicUrl}/admin/two-factor-auth/update-phone?phoneNumber=123456789`, 1500).reply(() => {
      return [
        200,
        {
          code: 'OK',
          content: true
        }
      ];
    });

    this.fuseMockApiService.onPut(`${this.apiPublicUrl}/admin/two-factor-auth/verify-short-code?shortCode=123`, 1500).reply(() => {
      return [
        200,
        {
          code: 'OK',
          content: this.javiToken
        }
      ];
    });
  }

  private _base64url(source: any): string {
    let encodedSource = Base64.stringify(source);

    encodedSource = encodedSource.replace(/=+$/, '');

    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }

  private _generateJWTToken(): string {
    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };

    const date = new Date();
    const iat = Math.floor(date.getTime() / 1000);
    const exp = Math.floor(date.setDate(date.getDate() + 7) / 1000);

    const payload: any = {
      iat: iat,
      iss: 'Fuse',
      exp: exp
    };

    const stringifiedHeader = Utf8.parse(JSON.stringify(header));
    const encodedHeader = this._base64url(stringifiedHeader);

    const stringifiedPayload = Utf8.parse(JSON.stringify(payload));
    const encodedPayload = this._base64url(stringifiedPayload);

    let signature: any = encodedHeader + '.' + encodedPayload;
    signature = HmacSHA256(signature, this._secret);
    signature = this._base64url(signature);

    return encodedHeader + '.' + encodedPayload + '.' + signature;
  }

  private _verifyJWTToken(token: string): boolean {
    const parts = token.split('.');
    const header = parts[0];
    const payload = parts[1];
    const signature = parts[2];

    const signatureCheck = this._base64url(HmacSHA256(header + '.' + payload, this._secret));

    return signature === signatureCheck;
  }
}
