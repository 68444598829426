import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromUser } from 'app/core/user/+state/user.reducer';
import { fromAuth } from '../data-access/+state/auth.reducer';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (_route, state) => {
  const router = inject(Router);
  const store = inject(Store);
  const authenticate$ = store.selectSignal(fromAuth.isAuthenticated);
  const user$ = store.selectSignal(fromUser.selectUser);

  if (!authenticate$() && (!state.url.includes('/unlock-session') || !user$()?.usuario)) {
    const redirectURL = state.url === '/sign-out' || state.url.includes('/unlock-session') ? '' : `redirectURL=${state.url}`;
    return router.parseUrl(`/sign-in?${redirectURL}`);
  }

  return true;
};
