import { Scheme, Screens, Theme, Themes } from '@fuse/services/config';
import { createFeature, createReducer, on } from '@ngrx/store';
import { SettingsActions } from './settings.actions';

export interface SettingsState {
  layout: string;
  scheme: Scheme;
  theme: Theme;
  screens: Screens;
  themes: Themes;
}

export const initialState: SettingsState = {
  layout: 'classy',
  scheme: 'auto',
  theme: 'theme-brand',
  screens: {},
  themes: []
};

export const settingsFeature = createFeature({
  name: 'settings',
  reducer: createReducer(
    initialState,
    on(SettingsActions.changeLayout, (state, { layout }) => ({ ...state, layout })),
    on(SettingsActions.changeScheme, (state, { scheme }) => ({ ...state, scheme })),
    on(SettingsActions.changeTheme, (state, { theme }) => ({ ...state, theme })),
    on(SettingsActions.setData, (state, { config }) => config)
  )
});

const { name, reducer, ...selectors } = settingsFeature;
export const fromSettings = selectors;
