<fuse-loading-bar />
@if (isScreenSmall) {
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
  >
    <ng-container fuseVerticalNavigationContentHeader>
      <div class="flex h-20 items-center px-8 pt-6">
        <img class="w-24" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
}
<div class="flex w-full min-w-0 flex-auto flex-col items-center bg-gray-200 dark:bg-card">
  <div class="relative z-49 flex w-full justify-center overflow-hidden bg-primary-700 print:hidden">
    <div
      class="bg-card w-full max-w-360 overflow-hidden border-b sm:m-8 sm:mb-0 sm:rounded-t-xl sm:py-3 sm:shadow-2xl md:mx-8 md:mt-12 md:pb-3 md:pt-4"
    >
      <div class="relative flex h-16 flex-auto items-center px-4 md:px-6">
        @if (!isScreenSmall) {
          <div class="mx-2 flex items-center">
            <img class="w-24 dark:hidden" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
            <img class="hidden w-24 dark:flex" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
          </div>
        }
        @if (isScreenSmall) {
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
          </button>
        }
        <div class="ml-auto flex items-center space-x-1 pl-2 sm:space-x-2">
          <languages />
          <fuse-fullscreen class="hidden md:block" />
          <search [appearance]="'bar'" />
          <user />
        </div>
      </div>
      @if (!isScreenSmall) {
        <div class="relative flex h-16 flex-auto items-center px-4">
          <fuse-horizontal-navigation [name]="'mainNavigation'" [navigation]="navigation.horizontal" />
        </div>
      }
    </div>
  </div>
  <div class="flex w-full flex-auto justify-center sm:px-8">
    <div class="bg-default flex w-full flex-auto flex-col sm:max-w-360 sm:overflow-hidden sm:shadow-xl">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="relative z-49 flex w-full justify-center print:hidden">
    <div class="bg-card flex h-14 w-full max-w-360 items-center border-t px-6 dark:bg-default sm:h-20 sm:shadow-xl md:px-8">
      <span class="text-secondary font-medium">Perrelet &copy; {{ currentYear }}</span>
    </div>
  </div>
</div>
