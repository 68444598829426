import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { LanguageActions } from './+state/languages.actions';
import { fromLanguage } from './+state/languages.reducer';

@Component({
  selector: 'languages',
  templateUrl: './languages.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'languages',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor]
})
export class LanguagesComponent {
  store = inject(Store);
  availableLangs$ = this.store.selectSignal(fromLanguage.selectAllLanguages);
  activeLang$ = this.store.selectSignal(fromLanguage.selectSelectedId);

  setActiveLang(lang: string): void {
    this.store.dispatch(LanguageActions.changeLanguage({ selectedId: lang }));
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
