import { createFeature, createReducer, on } from '@ngrx/store';
import { AlertType } from 'app/shared/models/alert-option';
import { AlertActions } from './alert.actions';

export interface IAlertState {
  code: string | null;
  message: string | null;
  alertType: AlertType | null;
}

const initialState: IAlertState = {
  code: null,
  message: null,
  alertType: null
};

export const alertsFeature = createFeature({
  name: 'alertState',
  reducer: createReducer(
    initialState,
    on(AlertActions.showAlert, (state, { code, message, alertType }) => ({
      ...state,
      code: code ?? null,
      message,
      alertType
    })),
    on(AlertActions.resetAlertAction, () => initialState)
  )
});

const { name, reducer, ...selectors } = alertsFeature;
export const fromAlerts = selectors;
