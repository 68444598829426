import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IAlertOption } from 'app/shared/models/alert-option';

export const AlertActions = createActionGroup({
  source: 'Alert',
  events: {
    showAlert: props<IAlertOption>(),
    resetAlertAction: emptyProps()
  }
});
