import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private translocoService = inject(TranslocoService);
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/public/') === -1 && req.url.indexOf('/private/') === -1) {
      return next.handle(req);
    }

    const params = new HttpParams({ fromString: req.params.toString() })
      .append('idioma', this.translocoService.getActiveLang())
      .append('platform', 'PERR');

    req = req.clone({
      headers: this.addHeaders(req.headers),
      params
    });

    return next.handle(req);
  }

  private addHeaders(headers: HttpHeaders): HttpHeaders {
    headers = headers
      .append('Cache-Control', 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0')
      .append('Pragma', 'no-cache')
      .append('Expires', '0');

    return headers;
  }
}
