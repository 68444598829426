import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AUTH_STORAGE_KEY } from 'app/shared/constants/app-const';
import { cleanNilValues } from 'app/shared/helpers/clean-nil-values';
import { IAuthenticate } from 'app/shared/models/authenticate';
import { ILogin } from 'app/shared/models/login';
import { IResponse } from 'app/shared/models/response';
import { BaseService } from 'app/shared/services/base.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  forgotPassword(user: { email: string; usuario: string }): Observable<any> {
    const params = new HttpParams({ fromObject: cleanNilValues(user) });
    return this._httpClient.get(`${this.apiPublicUrl}/admin/usuarios/crear_enlace`, { params });
  }

  changePassword(userId: string, data: { newPassword: string; oldPassword: string }): Observable<any> {
    return this._httpClient.post(`${this.apiPublicUrl}/admin/usuarios/${userId}`, data);
  }

  authenticate(credentials: ILogin): Observable<IAuthenticate> {
    return this._httpClient.post<IAuthenticate>(`${this.apiPublicUrl}/admin/usuarios/authenticate`, credentials);
  }

  signInUsingToken(): Observable<any> {
    return this._httpClient.post(`${this.apiPublicUrl}/auth/sign-in-with-token`, {
      accessToken: localStorage.getItem(AUTH_STORAGE_KEY)
    });
  }

  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  resetPassword(nuevaContrasenya: string, token: string): Observable<IResponse> {
    const params = new HttpParams({ fromObject: cleanNilValues({ nuevaContrasenya, token }) });
    return this._httpClient.post<IResponse>(`${environment.apiPublicUrl}/admin/usuarios/cambiar_contrasenya_acceso`, {}, { params });
  }
}
