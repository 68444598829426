import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AlertActions } from 'app/core/alert/+state/alert.actions';
import { AuthActions } from 'app/core/auth/data-access/+state/auth.actions';
import { fromAuth } from 'app/core/auth/data-access/+state/auth.reducer';
import { NavigationActions } from 'app/core/navigation/+state/navigation.actions';
import { AUTH_STORAGE_KEY } from 'app/shared/constants/app-const';
import { ValidarResponse } from 'app/shared/helpers/validators/validar-response';
import { AlertType } from 'app/shared/models/alert-option';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { UserService } from '../user.service';
import { UserActions } from './user.actions';
import { fromUser } from './user.reducer';
@Injectable()
export class UserEffects {
  private userService = inject(UserService);
  private action$ = inject(Actions);
  private store = inject(Store);
  private router = inject(Router);
  private jwtHelper = inject(JwtHelperService);

  user$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActions.initData),
      concatLatestFrom(() => [this.store.select(fromAuth.selectUserId), this.store.select(fromAuth.twoFactorAuthEnabled)]),
      switchMap(([, userId, twoFactorAuthEnabled]) => {
        return this.userService.getUsuario(userId).pipe(
          mergeMap(response => {
            return !twoFactorAuthEnabled
              ? [UserActions.loadSuccess({ user: response.content })]
              : [UserActions.loadSuccess({ user: response.content }), UserActions.checkTwoFactorAuth()];
          })
        );
      })
    )
  );

  twoFactorAuth$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(UserActions.checkTwoFactorAuth),
        concatLatestFrom(() => [
          this.store.select(fromAuth.twoFactorAuthEnabled),
          this.store.select(fromAuth.twoFactorAuthValidCode),
          this.store.select(fromAuth.twoFactorAuthPhoneNumber),
          this.store.select(fromAuth.twoFactorAuthLogged)
        ]),
        map(([, twoFactorAuthEnabled, twoFactorAuthValidCode, twoFactorAuthPhoneNumber, twoFactorAuthLogged]) => {
          if (!twoFactorAuthEnabled || twoFactorAuthLogged) {
            this.router.navigate(['/dashboard']);
          } else if (twoFactorAuthEnabled && !twoFactorAuthPhoneNumber) {
            this.router.navigate(['/two-factor-auth-phone-number']);
          } else if (twoFactorAuthEnabled && twoFactorAuthPhoneNumber) {
            this.router.navigate(['/two-factor-auth-send-code']);
            //Only regenerate Code if the actual code is invalid
            if (!twoFactorAuthValidCode) {
              this.store.dispatch(UserActions.twoFactorAuthGenerateCode());
            }
          }
        })
      ),
    { dispatch: false }
  );

  twoFactorAuthSendPhoneNumber$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActions.twoFactorAuthSendPhoneNumber),
      switchMap(({ phoneNumber }) =>
        this.userService.addPhoneNumber2FA(phoneNumber).pipe(
          mergeMap(response => {
            if (ValidarResponse.validar(response)) {
              this.router.navigate(['/two-factor-auth-send-code']);
              return [
                AuthActions.setPhoneNumber({ phoneNumber }),
                UserActions.twoFactorAuthSendPhoneNumberSuccess(),
                UserActions.twoFactorAuthGenerateCode()
              ];
            } else {
              return [UserActions.twoFactorAuthSendPhoneNumberFail()];
            }
          }),
          catchError(() => {
            return of(UserActions.twoFactorAuthSendPhoneNumberFail());
          })
        )
      )
    )
  );

  twoFactorAuthGenerateCode$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActions.twoFactorAuthGenerateCode),
      switchMap(() => this.userService.generate2FACode().pipe(map(() => UserActions.twoFactorAuthGenerateCodeSuccess())))
    )
  );

  editUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActions.editUser),
      concatLatestFrom(() => [this.store.select(fromAuth.selectUserId), this.store.select(fromUser.selectUser)]),
      switchMap(([{ user }, userId, userStore]) => {
        return this.userService.editUser(userId, { ...userStore, ...user }).pipe(
          mergeMap(response => [
            AlertActions.showAlert({
              alertType: AlertType.SUCCESS,
              message: 'commons.messages.success-edit-user'
            }),
            UserActions.editSuccess({ user: response.content })
          ])
        );
      })
    )
  );

  twoFactorAuthSendCode$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActions.twoFactorAuthSendCode),
      concatLatestFrom(() => [this.store.select(fromAuth.selectRememberMe)]),
      switchMap(([{ code }, rememberMe]) =>
        this.userService.verify2FACode(code).pipe(
          mergeMap(response => {
            if (ValidarResponse.validar(response)) {
              if (rememberMe) {
                localStorage.setItem(AUTH_STORAGE_KEY, response?.content);
              }
              return [
                AuthActions.signInSuccess({
                  accessToken: response?.content,
                  decodedToken: this.jwtHelper.decodeToken(response?.content),
                  rememberMe: rememberMe
                }),
                NavigationActions.initData()
              ];
            } else {
              this.store.dispatch(
                AlertActions.showAlert({
                  message: response.code,
                  alertType: AlertType.ERROR
                })
              );
              return [UserActions.twoFactorAuthSendCodeFail()];
            }
          }),
          catchError(() => {
            return of(UserActions.twoFactorAuthSendCodeFail());
          })
        )
      )
    )
  );
}
