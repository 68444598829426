import { Injectable } from '@angular/core';
import { BaseMockApi } from 'app/mock-api/base.api';
import { user as userData } from 'app/mock-api/common/user/user.data';
import { assign, cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class UserMockApi extends BaseMockApi {
  private _user: any = userData;

  registerHandlers(): void {
    const userId = '54949ca596930f83ef1b0b2c6c8fe5ad33e7fb7e818eb06d7440ca3377404f7b';
    this.fuseMockApiService
      .onGet(`${this.apiPublicUrl}/admin/usuarios/${userId}`)
      .reply(() => [200, { code: 'OK', content: cloneDeep(this._user) }]);

    this.fuseMockApiService.onPut(`${this.apiPublicUrl}/admin/usuarios/${userId}`).reply(({ request }) => {
      const user = cloneDeep(request.body);
      this._user = assign({}, this._user, user);
      return [200, { content: cloneDeep(this._user) }];
    });
  }
}
