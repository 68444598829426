import { inject, Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { UserActions } from 'app/core/user/+state/user.actions';
import { fromUser } from 'app/core/user/+state/user.reducer';
import { LanguageActions } from 'app/layout/common/languages/+state/languages.actions';
import { fromLanguage } from 'app/layout/common/languages/+state/languages.reducer';
import { map, mergeMap, switchMap } from 'rxjs';
import { NavigationService } from '../navigation.service';
import { NavigationActions } from './navigation.actions';

@Injectable()
export class NavigationEffects {
  private navigationService = inject(NavigationService);
  private action$ = inject(Actions);
  private store = inject(Store);

  initData$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActions.loadSuccess, LanguageActions.changeLanguageSuccess),
      concatLatestFrom(() => [this.store.select(fromUser.selectUser), this.store.select(fromLanguage.selectSelectedId)]),
      switchMap(([, user, lang]) => {
        return this.navigationService.getNavigation(user?.idUsuario).pipe(
          mergeMap(response => {
            const navigation = this.formatResponse(response, lang);
            return [NavigationActions.setData({ navigation }), UserActions.updateUser({ user: response?.content?.user })];
          })
        );
      })
    )
  );

  registerModuleAccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(NavigationActions.registerModuleAccess),
      concatLatestFrom(() => [this.store.select(fromUser.selectUser)]),
      switchMap(([{ idModulo, sede, sedeType }, user]) => {
        return this.navigationService.registerModuleAccess(user?.idUsuario, idModulo, sede, sedeType).pipe(
          map(() => {
            return NavigationActions.registerModuleAccessSuccess();
          })
        );
      })
    )
  );

  formatResponse(response, lang: string): FuseNavigationItem[] {
    const processItems = item => {
      const itemPath = item.sedes ? item.sedes[0]?.path : '';
      const sedeCodigoInterno = item.sedes ? item.sedes[0]?.codigoInterno : '';
      let _item = {
        id: item.codigo,
        title: item.nombre,
        externalLink: item.externo,
        target: item.externo ? '_blank' : '_self',
        type: item.modulosErps ? 'collapsable' : 'basic',
        icon: 'heroicons_outline:home',
        link: item.externo
          ? `${itemPath}${itemPath?.includes('?') ? '&' : '?'}username=${response?.content?.usuario?.usuario}&password=${
              response?.content?.usuario?.contrasenya
            }&locale=${lang.replace('-', '_')}&aplicacion=${item?.codigo}${sedeCodigoInterno}&sede=${sedeCodigoInterno}`
          : itemPath
      };

      if (item.modulosErps) {
        return { ..._item, children: item.modulosErps.map(processItems) };
      }

      return _item;
    };

    return response.content.aplicaciones.map(processItems);
  }
}
