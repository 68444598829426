import { ActionReducer } from '@ngrx/store';
import { AuthActions } from './auth/data-access/+state/auth.actions';

export const clearStateMetaReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (action.type === AuthActions.signOut.type) {
      state = { settings: { ...state.settings }, languageState: { ...state.languageState } };
    }
    return reducer(state, action);
  };
