<fuse-loading-bar />
<fuse-vertical-navigation
  class="dark bg-primary print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.default"
  [opened]="!isScreenSmall"
>
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex w-full items-center p-4 pl-6">
      <div class="flex items-center justify-center">
        <img class="w-20" src="assets/images/logo/perrelet.png" />
      </div>
      <div class="ml-auto flex items-center">
        <user [showAvatar]="false"></user>
      </div>
    </div>
    <div class="flex w-full flex-col items-center p-4">
      <div class="relative h-24 w-24">
        @if (user?.imagen) {
          <img class="h-full w-full rounded-full" [src]="user?.imagen" alt="User avatar" />
        } @else {
          <mat-icon class="icon-size-24" [svgIcon]="'heroicons_solid:user-circle'" />
        }
      </div>
      <div class="mt-6 flex w-full flex-col items-center justify-center">
        <div class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center font-medium leading-normal">
          {{ user?.fullName }}
        </div>
        <div
          class="text-secondary mt-0.5 w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-md font-medium leading-normal"
        >
          {{ user?.email }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container fuseVerticalNavigationContentFooter>
    <div class="mb-4 mt-2 flex h-16 flex-0 items-center justify-center pl-2 pr-6 opacity-12">
      <img class="max-w-36" src="assets/images/logo/perrelet.png" />
    </div>
  </ng-container>
</fuse-vertical-navigation>
<div class="flex w-full min-w-0 flex-auto flex-col">
  <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
  >
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'" />
    </button>
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block" />
      <search [appearance]="'bar'" />
    </div>
  </div>
  <div class="flex flex-auto flex-col">
    <router-outlet />
  </div>
</div>
