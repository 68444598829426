import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { cleanNilValues } from 'app/shared/helpers/clean-nil-values';
import { IResponse } from 'app/shared/models/response';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {
  recaptchaUrl = environment.apiPublicUrl.concat('recaptcha');

  private http = inject(HttpClient);

  sendReCaptcha(token: string) {
    const params = new HttpParams({ fromObject: cleanNilValues({ token }) });
    return this.http.get<IResponse>(this.recaptchaUrl.concat('/siteverify/platform'), {
      params
    });
  }
}
