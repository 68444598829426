import { AcademyMockApi } from 'app/mock-api/apps/academy/academy.api';
import { ContactsMockApi } from 'app/mock-api/apps/contacts/contacts.api';
import { ECommerceInventoryMockApi } from 'app/mock-api/apps/ecommerce/inventory/inventory.api';
import { FileManagerMockApi } from 'app/mock-api/apps/file-manager/file-manager.api';
import { HelpCenterMockApi } from 'app/mock-api/apps/help-center/help-center.api';
import { MailboxMockApi } from 'app/mock-api/apps/mailbox/mailbox.api';
import { NotesMockApi } from 'app/mock-api/apps/notes/notes.api';
import { ScrumboardMockApi } from 'app/mock-api/apps/scrumboard/scrumboard.api';
import { TasksMockApi } from 'app/mock-api/apps/tasks/tasks.api';
import { AuthMockApi } from 'app/mock-api/common/auth/auth.api';
import { MessagesMockApi } from 'app/mock-api/common/messages/messages.api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/navigation.api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/notifications.api';
import { SearchMockApi } from 'app/mock-api/common/search/search.api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/shortcuts.api';
import { UserMockApi } from 'app/mock-api/common/user/user.api';
import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/analytics.api';
import { CryptoMockApi } from 'app/mock-api/dashboards/crypto/crypto.api';
import { FinanceMockApi } from 'app/mock-api/dashboards/finance/finance.api';
import { ProjectMockApi } from 'app/mock-api/dashboards/project/project.api';
import { ActivitiesMockApi } from 'app/mock-api/pages/activities/activities.api';
import { IconsMockApi } from 'app/mock-api/ui/icons/icons.api';

export const mockApiServices = [
  AcademyMockApi,
  ActivitiesMockApi,
  AnalyticsMockApi,
  AuthMockApi,
  ContactsMockApi,
  CryptoMockApi,
  ECommerceInventoryMockApi,
  FileManagerMockApi,
  FinanceMockApi,
  HelpCenterMockApi,
  IconsMockApi,
  MailboxMockApi,
  MessagesMockApi,
  NavigationMockApi,
  NotesMockApi,
  NotificationsMockApi,
  ProjectMockApi,
  SearchMockApi,
  ScrumboardMockApi,
  ShortcutsMockApi,
  TasksMockApi,
  UserMockApi
];
