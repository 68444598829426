<fuse-loading-bar />
@if (isScreenSmall) {
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
  >
    <ng-container fuseVerticalNavigationContentHeader>
      <div class="flex h-20 items-center px-8 pt-6">
        <img class="w-24" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
}
<div class="flex w-full min-w-0 flex-auto flex-col items-center bg-gray-200 dark:bg-card">
  <div
    class="relative z-49 flex h-16 w-full flex-0 flex-col justify-center overflow-hidden shadow dark:shadow-none sm:h-20 md:h-36 print:hidden"
  >
    <div class="dark relative flex w-full flex-auto justify-center bg-primary px-4 dark:bg-gray-900 md:px-8">
      <div class="flex h-16 w-full max-w-360 items-center sm:h-20">
        @if (!isScreenSmall) {
          <div class="flex items-center">
            <img class="w-24" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
          </div>
        }
        @if (isScreenSmall) {
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'" />
          </button>
        }
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
          <languages />
          <fuse-fullscreen class="hidden md:block" />
          <search [appearance]="'bar'" />
          <user />
        </div>
      </div>
    </div>
    @if (!isScreenSmall) {
      <div class="bg-card flex flex-auto justify-center px-4 dark:bg-gray-700 md:px-8">
        <div class="relative flex h-16 w-full max-w-360 items-center">
          <fuse-horizontal-navigation
            class="-mx-4"
            [name]="'mainNavigation'"
            [navigation]="navigation.horizontal"
          ></fuse-horizontal-navigation>
        </div>
      </div>
    }
  </div>
  <div class="flex w-full flex-auto justify-center sm:p-6 md:p-8">
    <div class="bg-default flex w-full flex-auto flex-col sm:max-w-360 sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="bg-card relative z-49 flex w-full flex-0 justify-center border-t px-6 md:px-8 print:hidden">
    <div class="flex h-14 w-full max-w-360 items-center sm:h-20">
      <span class="text-secondary font-medium">Perrelet &copy; {{ currentYear }}</span>
    </div>
  </div>
</div>
