import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserIdleService } from 'angular-user-idle';
import { IDLE_STATUS, TABS_STATUS_LIST, TAB_ID } from 'app/shared/constants/app-const';
import { AuthActions } from '../auth/data-access/+state/auth.actions';
import { ITabIdle } from './idle.model';

@Injectable({
  providedIn: 'root'
})
export class IdleManagementService {
  private userIdle = inject(UserIdleService);
  private store = inject(Store);

  manageTabStatus(data: StorageEvent) {
    if (data.key === TABS_STATUS_LIST) {
      const activeTab = this.getTabStatusList()?.find(tab => tab.active);
      if (activeTab) {
        activeTab.id === this.getTabId() ? this.startWatching() : this.stopWatching();
      } else {
        this.getTabStatusList()[0]?.id === this.getTabId() ? this.startWatching() : this.stopWatching();
      }
    } else if (data.key === IDLE_STATUS && this.getIdleStatus() === 'idle') {
      this.store.dispatch(AuthActions.lockSession());
    }
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.setIdleStatus('active');
    this.userIdle.startWatching();
  }

  generateTabId() {
    if (!this.getTabId()) {
      sessionStorage.setItem(TAB_ID, new Date().getTime().toString());
    }
  }

  private getTabId() {
    return sessionStorage.getItem(TAB_ID);
  }

  setTabStatusList() {
    if (!this.getTabStatusList()) {
      localStorage.setItem(TABS_STATUS_LIST, JSON.stringify([{ id: this.getTabId(), active: document.hasFocus() } as ITabIdle]));
    } else {
      localStorage.setItem(
        TABS_STATUS_LIST,
        JSON.stringify([
          ...this.getTabStatusList()?.filter(tab => tab.id !== this.getTabId()),
          { id: this.getTabId(), active: document.hasFocus() } as ITabIdle
        ])
      );
    }
  }

  private getTabStatusList(): ITabIdle[] | null {
    return localStorage.getItem(TABS_STATUS_LIST) ? JSON.parse(localStorage.getItem(TABS_STATUS_LIST)) : null;
  }

  removeFromTabStatusList() {
    const tempTabStatusList = this.getTabStatusList()?.filter(tab => tab.id !== this.getTabId());
    if (tempTabStatusList.length !== 0) {
      localStorage.setItem(TABS_STATUS_LIST, JSON.stringify(tempTabStatusList));
    } else {
      localStorage.removeItem(TABS_STATUS_LIST);
    }
  }

  private getIdleStatus() {
    return localStorage.getItem(IDLE_STATUS);
  }

  setIdleStatus(status: 'active' | 'idle') {
    localStorage.setItem(IDLE_STATUS, status);
  }
}
