import { Injectable } from '@angular/core';
import { BaseMockApi } from 'app/mock-api/base.api';
import { activities as activitiesData } from 'app/mock-api/pages/activities/activities.data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ActivitiesMockApi extends BaseMockApi {
  private _activities: any = activitiesData;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Activities - GET
    // -----------------------------------------------------------------------------------------------------
    this.fuseMockApiService.onGet('api/pages/activities').reply(() => [200, cloneDeep(this._activities)]);
  }
}
