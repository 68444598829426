import { FuseConfig, Scheme } from '@fuse/services/config';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const settings = createActionGroup({
  source: 'Settings',
  events: {
    initData: emptyProps(),
    setData: props<{ config: FuseConfig }>(),
    changeLayout: props<{ layout: string }>(),
    changeScheme: props<{ scheme: Scheme }>(),
    changeTheme: props<{ theme: string }>()
  }
});

export const SettingsActions = {
  ...settings
};
