<fuse-loading-bar />
<fuse-vertical-navigation
  class="dark bg-primary text-white print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.futuristic"
  [opened]="!isScreenSmall"
>
  <ng-container fuseVerticalNavigationHeader>
    <div class="flex h-20 items-center justify-center p-6 pb-0">
      <img class="w-20" src="assets/images/logo/perrelet.png" />
    </div>
  </ng-container>
  <ng-container fuseVerticalNavigationFooter>
    <div class="flex w-full items-center border-t px-6 py-8">
      <user />
      <div class="ml-4 flex w-full flex-col overflow-hidden">
        <div class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-normal text-current opacity-80">
          {{ user.fullName }}
        </div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>
<div class="flex w-full min-w-0 flex-auto flex-col">
  <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
  >
    <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'" />
    </button>
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
      <languages />
      <fuse-fullscreen class="hidden md:block" />
      <search [appearance]="'bar'" />
    </div>
  </div>
  <div class="flex flex-auto flex-col">
    <router-outlet></router-outlet>
  </div>
  <div
    class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
  >
    <span class="text-secondary font-medium">Perrelet &copy; {{ currentYear }}</span>
  </div>
</div>
