import { Injectable } from '@angular/core';
import { IResponse } from 'app/shared/models/response';
import { BaseService } from 'app/shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService extends BaseService {
  getNavigation(userId: string): Observable<IResponse> {
    return this._httpClient.get<IResponse>(`${this.apiPublicUrl}/admin/usuarios/main_menu/${userId}`);
  }

  registerModuleAccess(idUsuarioAES, idModuloAES: string, sede: string, sedeType: number): Observable<IResponse> {
    const registroModulo = {
      idUsuarioAES,
      idModuloAES,
      sede,
      sedeType: sedeType?.toString() ?? null
    };

    return this._httpClient.post<IResponse>(`${this.apiPublicUrl}/admin/usuarios/registroAccesoModulo`, registroModulo);
  }
}
