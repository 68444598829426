import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { UserService } from 'app/core/user/user.service';
import { AUTH_STORAGE_KEY } from 'app/shared/constants/app-const';
import { map, Observable } from 'rxjs';
import { AuthActions } from '../data-access/+state/auth.actions';
import { fromAuth } from '../data-access/+state/auth.reducer';

@Injectable({
  providedIn: 'root'
})
export class AutologinGuard {
  private router = inject(Router);
  private userService = inject(UserService);
  private store = inject(Store);
  private jwtHelper = inject(JwtHelperService);
  authenticate$ = this.store.selectSignal(fromAuth.isAuthenticated);

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (this.authenticate$()) {
      this.store.dispatch(AuthActions.signOut());
    }

    let token = next.paramMap.get('token');

    if (!token) {
      this.router.navigate(['/pages/login']);
      return true;
    }

    return this.userService.autologin(token).pipe(
      map(({ status, privateKey }) => {
        if (status === 'success') {
          const accessToken = privateKey;
          localStorage.setItem(AUTH_STORAGE_KEY, accessToken);
          const redirect = !next.queryParamMap.get('path') ? '/dashboard' : `/${next.queryParamMap.get('path').split('_').join('/')}`;
          this.store.dispatch(
            AuthActions.signInWithTokenSuccess({
              accessToken,
              decodedToken: this.jwtHelper.decodeToken(accessToken)
            })
          );
          this.router.navigate([redirect]);
        }
        return status !== 'success';
      })
    );
  }
}
