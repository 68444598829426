import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, makeEnvironmentProviders } from '@angular/core';
import { isFunction, isObject, isString, TRANSLOCO_LOADER } from '@jsverse/transloco';
import { isObservable, from, of } from 'rxjs';
import { switchMap, tap, map, take, filter } from 'rxjs/operators';
const dayInMilliseconds = 86400;
const defaultConfig = {
  ttl: dayInMilliseconds,
  storageKey: '@transloco/translations'
};
const TRANSLOCO_PERSIST_TRANSLATIONS_LOADER = new InjectionToken('TRANSLOCO_PERSIST_TRANSLATIONS_LOADER');
const TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE = new InjectionToken('The storage to use for the persistance behavior');
const TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE_CONFIG = new InjectionToken('Configuration for the storage behavior');
function isPromise(v) {
  return v && isFunction(v.then);
}
function observify(asyncOrValue) {
  if (isPromise(asyncOrValue) || isObservable(asyncOrValue)) {
    return from(asyncOrValue);
  }
  return of(asyncOrValue);
}
function getTimestampKey(key) {
  return `${key}/timestamp`;
}
class TranslocoPersistTranslations {
  loader = inject(TRANSLOCO_PERSIST_TRANSLATIONS_LOADER);
  storage = inject(TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE);
  config = inject(TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE_CONFIG);
  subscription = this.clearCurrentStorage().subscribe();
  cache = null;
  getTranslation(lang) {
    const storageKey = this.config.storageKey;
    return this.getCached(storageKey).pipe(switchMap(translations => {
      if (translations?.[lang]) {
        return of(translations[lang]);
      }
      return from(this.loader.getTranslation(lang)).pipe(tap(translation => this.setCache(storageKey, lang, translation)));
    }));
  }
  clearCache() {
    this.clearTimestamp(this.config.storageKey);
    this.clearTranslations();
    this.cache = null;
  }
  getCached(key) {
    return this.cache ? of(this.cache) : observify(this.storage.getItem(key)).pipe(map(item => {
      return item ? this.decode(key, item) : null;
    }), tap(item => {
      this.cache = item;
    }), take(1));
  }
  setCache(key, lang, translation) {
    const translations = this.cache || {};
    translations[lang] = translation;
    this.setTimestamp(key);
    this.storage.setItem(key, this.encode(translations));
    this.cache = translations;
  }
  encode(value) {
    return JSON.stringify(value);
  }
  decode(key, item) {
    if (isObject(item)) {
      return item;
    } else if (isString(item)) {
      try {
        return JSON.parse(item);
      } catch (e) {
        console.error(`storage key: ${key} is not serializable`);
        return null;
      }
    }
    return null;
  }
  setTimestamp(key) {
    this.storage.setItem(getTimestampKey(key), Date.now());
  }
  getTimestamp(key) {
    return observify(this.storage.getItem(getTimestampKey(key))).pipe(map(parseInt));
  }
  clearTimestamp(key) {
    this.storage.removeItem(getTimestampKey(key));
  }
  clearTranslations() {
    this.storage.removeItem(this.config.storageKey);
  }
  clearCurrentStorage() {
    const storageKey = this.config.storageKey;
    return this.getTimestamp(storageKey).pipe(filter(Boolean), tap(time => {
      const isExpired = Date.now() - time >= this.config.ttl;
      if (isExpired) {
        this.storage.removeItem(storageKey);
      }
    }));
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
    // Caretaker note: it's important to clean up references to subscriptions since they save the `next`
    // callback within its `destination` property, preventing classes from being GC'd.
    this.subscription = null;
  }
  static ɵfac = function TranslocoPersistTranslations_Factory(t) {
    return new (t || TranslocoPersistTranslations)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: TranslocoPersistTranslations,
    factory: TranslocoPersistTranslations.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslocoPersistTranslations, [{
    type: Injectable
  }], null, null);
})();
function provideTranslocoPersistTranslations({
  storage,
  loader,
  ...storageConfig
}) {
  return makeEnvironmentProviders([{
    provide: TRANSLOCO_LOADER,
    useClass: TranslocoPersistTranslations
  }, provideTranslocoPersistTranslationsConfig(storageConfig), {
    provide: TRANSLOCO_PERSIST_TRANSLATIONS_LOADER,
    useClass: loader
  }, {
    provide: TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE,
    ...storage
  }]);
}
function provideTranslocoPersistTranslationsConfig(config) {
  return {
    provide: TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE_CONFIG,
    useValue: {
      ...defaultConfig,
      ...config
    }
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { TRANSLOCO_PERSIST_TRANSLATIONS_LOADER, TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE, TRANSLOCO_PERSIST_TRANSLATIONS_STORAGE_CONFIG, TranslocoPersistTranslations, provideTranslocoPersistTranslations };
