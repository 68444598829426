import { Injectable } from '@angular/core';
import { BaseMockApi } from 'app/mock-api/base.api';
import { crypto as cryptoData } from 'app/mock-api/dashboards/crypto/crypto.data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class CryptoMockApi extends BaseMockApi {
  private _crypto: any = cryptoData;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Crypto - GET
    // -----------------------------------------------------------------------------------------------------
    this.fuseMockApiService.onGet('api/dashboards/crypto').reply(() => [200, cloneDeep(this._crypto)]);
  }
}
