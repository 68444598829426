<fuse-loading-bar />
@if (isScreenSmall) {
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation?.default"
    [opened]="false"
  >
    <ng-container fuseVerticalNavigationContentHeader>
      <div class="flex h-20 items-center px-8 pt-6">
        <img class="w-24" src="assets/images/logo/perrelet.png" alt="Perrelet logo" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
}
<div class="flex w-full min-w-0 flex-auto flex-col">
  <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none sm:h-20 md:px-6 print:hidden"
  >
    @if (!isScreenSmall) {
      <div class="mx-2 flex items-center lg:mr-8">
        <div class="hidden lg:flex">
          <img class="w-24 dark:hidden" src="assets/images/logo/perrelet.png" />
          <img class="hidden w-24 dark:flex" src="assets/images/logo/perrelet.png" />
        </div>
        <img class="flex w-8 lg:hidden" src="assets/images/logo/perrelet.png" />
      </div>
      <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation?.horizontal" />
    }
    @if (!isScreenSmall) {
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'" />
      </button>
    }
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
      <languages />
      <fuse-fullscreen class="hidden md:block" />
      <search [appearance]="'bar'" />
      <user />
    </div>
  </div>
  <div class="flex w-full flex-auto flex-col">
    <router-outlet></router-outlet>
  </div>
  <div class="bg-card relative z-49 flex h-14 w-full flex-0 items-center border-t px-4 dark:bg-transparent sm:h-20 md:px-6 print:hidden">
    <span class="text-secondary font-medium">Perrelet &copy; {{ currentYear }}</span>
  </div>
</div>
