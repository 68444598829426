import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseHorizontalNavigationComponent, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { UserComponent } from 'app/layout/common/user/user.component';

@Component({
  selector: 'material-layout',
  templateUrl: './material.component.html',
  standalone: true,
  imports: [
    FuseLoadingBarComponent,
    FuseVerticalNavigationComponent,
    MatButtonModule,
    MatIconModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchComponent,
    UserComponent,
    FuseHorizontalNavigationComponent,
    RouterOutlet
  ]
})
export class MaterialLayoutComponent {
    @Input()  navigation: any
    @Input()  isScreenSmall: boolean
    @Output() toggle= new EventEmitter<string>()

  get currentYear(): number {
    return new Date().getFullYear();
  }

  toggleNavigation(name: string): void {
    this.toggle.emit(name)
  }
}
