<ng-container *transloco="let t">
  <!-- Button -->
  <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
      @if (showAvatar && user$()?.imagen) {
        <img class="h-7 w-7 rounded-full" [src]="user$()?.imagen" />
      } @else {
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'" />
      }
    </span>
  </button>

  <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span>{{ t('commons.label.signed-in-as') }}</span>
        <span class="mt-1.5 text-md font-medium">{{ user$()?.usuario }}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="['/profile']">
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span>{{ t('commons.label.profile') }}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
      <span>{{ t('commons.label.sign-out') }}</span>
    </button>
  </mat-menu>
</ng-container>
