/* eslint-disable */
export const user = {
  accesoExterno: false,
  activo: true,
  apellidos: 'Pinto',
  autenticaPorEmail: false,
  codigoIdioma: 'es-ES',
  contrasenyaSegura: false,
  controlCambioEmail: false,
  copiarModulos: false,
  email: 'jpinto@festina.com',
  emailValido: false,
  fechaNacimiento: '1989-12-10T00:00:00+0100',
  fullName: 'Javier Pinto',
  idUsuario: '54949ca596930f83ef1b0b2c6c8fe5ad33e7fb7e818eb06d7440ca3377404f7b',
  imagen: 'https://media6.festinagroup.com/static/usuarios/perfil/54949ca596930f83ef1b0b2c6c8fe5ad33e7fb7e818eb06d7440ca3377404f7b.jpeg',
  nombre: 'Javier',
  perfil: {
    codigoInterno: 'INF',
    idPerfilAES: '2439f66843bab990c34fd19931e5aa675f079fb143ce6a199dc6e18305a0303e',
    nombre: 'Informática'
  },
  sede: {
    idSedeAES: 'b68635ad2f54d01989f895e36605689c',
    nombre: 'España',
    codigoInterno: 'ES',
    codigoJbase: '1',
    conexionJbase: 'Barcelona',
    cuentaJbaseNet: 'ORO_NET',
    type: 1
  },
  solicitudCambioEmail: false,
  ultimoAcceso: '2024-05-09T17:18:26+0200',
  userUuid: 'a8618b63-811f-11ed-8779-0ab26a452fcd',
  usuario: 'admin'
};
