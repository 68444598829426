import { inject } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { environment } from 'environments/environment';

export abstract class BaseMockApi {
  readonly apiPublicUrl = environment.apiPublicUrl;
  readonly apiPrivateUrl = environment.apiPrivateUrl;
  fuseMockApiService = inject(FuseMockApiService);

  constructor() {
    this.registerHandlers();
  }

  abstract registerHandlers(): void;
}
