import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AlertActions } from 'app/core/alert/+state/alert.actions';
import { AlertType } from 'app/shared/models/alert-option';
import { IResponse } from 'app/shared/models/response';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';
import { AuthActions } from '../data-access/+state/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(environment.apiPublicUrl)) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      map((response: any) => {
        if (response instanceof HttpResponse && response.body && instanceOfIResponse(response.body)) {
          if (response.body.code !== 'OK') {
            throw new HttpErrorResponse({
              error: {
                message: response.body.content,
                code: response.body.code
              },
              status: response.status
            });
          }
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
          alert(errorMessage);
        } else {
          switch (error.status as any) {
            case 401: {
              console.log('Error autenticacion');
              this.store.dispatch(AuthActions.signOut());
              break;
            }
            default: {
              errorMessage = `Error Code: ${error.error.code}\nMessage: ${error.error.message}`;
              this.store.dispatch(
                AlertActions.showAlert({
                  code: error.error.code?.toString(),
                  message: error.error?.message,
                  alertType: AlertType.ERROR
                })
              );
              break;
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
}
export function instanceOfIResponse(object: any): object is IResponse {
  return 'content' in object && 'code' in object;
}
