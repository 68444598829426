import { Component, HostBinding } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html'
})
export class AppComponent {
  @HostBinding('class') classes = 'flex flex-auto w-full h-full';

  constructor() {
    console.log('Version', environment.version);
  }
}
