@switch (layout) {
  @case ('empty') {
    <empty-layout />
  }
  @case ('centered') {
    <centered-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
  @case ('enterprise') {
    <enterprise-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
  @case ('material') {
    <material-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
  @case ('modern') {
    <modern-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
  @case ('classic') {
    <classic-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
  @case ('classy') {
    <classy-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" [user]="user$()" />
  }
  @case ('compact') {
    <compact-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
  @case ('dense') {
    <dense-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
  @case ('futuristic') {
    <futuristic-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" [user]="user$()" />
  }
  @case ('thin') {
    <thin-layout [isScreenSmall]="isScreenSmall" [navigation]="navigation$()" (toggle)="toggleNavigation($event)" />
  }
}
